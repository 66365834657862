import _ from 'lodash';
import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const getInitialState = () => {
  return {
    advice: null,
    accessId: null,
    challenge: null,
    bankCode: null,
    bic: null,
    accessMethods: null,
    credentials: null,
    largeIcon: null,
    name: null,
    smallIcon: null,
    catalogue: [],
    scenarios: [],
    apiStatus: {
      searchBank: {},
      getScenarios: {},
      createAccess: {},
      getAccessSyncStatus: {},
      selectAuthMethod: {},
      postAccessLoginSync: {}
    }
  };
};

const requestSearchBank = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      searchBank: request()
    }
  };
};

const successSearchBank = (state, action) => {
  const { bank } = action;
  return {
    ...state,
    catalogue: bank,
    apiStatus: {
      ...state.apiStatus,
      searchBank: result()
    }
  };
};

const errorSearchBank = (state, action) => {
  const { error } = action;
  return {
    ...state,
    catalogue: [],
    apiStatus: {
      ...state.apiStatus,
      searchBank: result(error)
    }
  };
};

const setSelectedBank = (state, action) => {
  const { bank } = action;
  return {
    ...state,
    ...bank,
    catalogue: [],
    apiStatus: {
      ...state.apiStatus
    }
  };
};

const resetBank = state => {
  return {
    advice: null,
    accessId: null,
    challenge: null,
    bankCode: null,
    bic: null,
    accessMethods: null,
    credentials: null,
    largeIcon: null,
    name: null,
    smallIcon: null,
    catalogue: [],
    apiStatus: {
      ...state.apiStatus
    }
  };
};

const setIbans = (state, action) => {
  const { ibans } = action;
  const accessMethodElement = state.accessMethods[0];
  accessMethodElement.ibans = ibans;
  return {
    ...state,
    accessMethods: [accessMethodElement]
  };
};

const setCredentials = (state, action) => {
  const { credentials } = action;
  return {
    ...state,
    credentials
  };
};

const requestCreateAccess = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createAccess: request()
    }
  };
};

const successCreateAccess = (state, action) => {
  const { bank } = action;

  return {
    ...state,
    accessId: bank.accessId,
    challenge: bank.challenge,
    apiStatus: {
      ...state.apiStatus,
      createAccess: result()
    }
  };
};

const errorCreateAccess = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createAccess: result(error)
    }
  };
};

const requestGetAccessSyncStatus = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccessSyncStatus: request()
    }
  };
};

const successGetAccessSyncStatus = (state, action) => {
  const { bank } = action;
  return {
    ...state,
    challenge: bank.challenge,
    apiStatus: {
      ...state.apiStatus,
      getAccessSyncStatus: result()
    }
  };
};

const errorGetAccessSyncStatus = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccessSyncStatus: result(error)
    }
  };
};

const requestSelectAuthMethod = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      selectAuthMethod: request()
    }
  };
};

const successSelectAuthMethod = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      selectAuthMethod: result()
    }
  };
};

const errorSelectAuthMethod = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      selectAuthMethod: result(error)
    }
  };
};

const requestPostAccessLoginSync = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postAccessLoginSync: request()
    }
  };
};

const successPostAccessLoginSync = state => {
  return {
    ...state,
    credentials: null,
    apiStatus: {
      ...state.apiStatus,
      postAccessLoginSync: result()
    }
  };
};

const errorPostAccessLoginSync = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    credentials: null,
    apiStatus: {
      ...state.apiStatus,
      postAccessLoginSync: result(error)
    }
  };
};

const requestGetScenarios = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getScenarios: request()
    }
  };
};

const successGetScenarios = (state, action) => {
  const { scenarios } = action;

  const sortedScenarios = _.orderBy(scenarios, [scenario => scenario.name.toLowerCase()], ['asc']);
  return {
    ...state,
    scenarios: sortedScenarios,
    apiStatus: {
      ...state.apiStatus,
      getScenarios: result()
    }
  };
};

const errorGetScenarios = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    scenarios: [],
    apiStatus: {
      ...state.apiStatus,
      getScenarios: result(error)
    }
  };
};

const handlers = {
  [types.REQUEST_SEARCH_BANK]: requestSearchBank,
  [types.SUCCESS_SEARCH_BANK]: successSearchBank,
  [types.ERROR_SEARCH_BANK]: errorSearchBank,
  [types.SET_SELECTED_BANK]: setSelectedBank,
  [types.RESET_BANK]: resetBank,
  [types.SET_IBANS]: setIbans,
  [types.REQUEST_CREATE_ACCESS]: requestCreateAccess,
  [types.SUCCESS_CREATE_ACCESS]: successCreateAccess,
  [types.ERROR_CREATE_ACCESS]: errorCreateAccess,
  [types.SET_CREDENTIALS]: setCredentials,
  [types.REQUEST_GET_ACCESS_SYNC_STATUS]: requestGetAccessSyncStatus,
  [types.SUCCESS_GET_ACCESS_SYNC_STATUS]: successGetAccessSyncStatus,
  [types.ERROR_GET_ACCESS_SYNC_STATUS]: errorGetAccessSyncStatus,
  [types.REQUEST_SELECT_AUTH_METHOD]: requestSelectAuthMethod,
  [types.SUCCESS_SELECT_AUTH_METHOD]: successSelectAuthMethod,
  [types.ERROR_SELECT_AUTH_METHOD]: errorSelectAuthMethod,
  [types.REQUEST_POST_ACCESS_LOGIN_SYNC]: requestPostAccessLoginSync,
  [types.SUCCESS_POST_ACCESS_LOGIN_SYNC]: successPostAccessLoginSync,
  [types.ERROR_POST_ACCESS_LOGIN_SYNC]: errorPostAccessLoginSync,
  [types.REQUEST_GET_SCENARIOS]: requestGetScenarios,
  [types.SUCCESS_GET_SCENARIOS]: successGetScenarios,
  [types.ERROR_GET_SCENARIOS]: errorGetScenarios
};
export default createReducer(getInitialState, handlers);
