import { push } from 'connected-react-router';
import types from './types';

const acceptTerms = () => {
  return {
    type: types.ACCEPT_TERMS
  };
};

const acceptPrivacy = () => {
  return {
    type: types.ACCEPT_PRIVACY
  };
};

const processData = () => {
  return {
    type: types.PROCESS_DATA
  };
};

const closeWelcomeModal = () => {
  return {
    type: types.CLOSE_WELCOME_MODAL
  };
};

const showSnackbarError = error => {
  return {
    type: types.SNACKBAR_ERROR_SHOW,
    error: {
      error
    }
  };
};

const hideSnackbarError = () => {
  return {
    type: types.SNACKBAR_ERROR_HIDE
  };
};

const setProgress = progress => {
  return {
    type: types.SET_PROGRESS,
    progress
  };
};

const setStep = step => {
  return {
    type: types.SET_STEP,
    step
  };
};

const setConfirmed = identifier => {
  return {
    type: types.SET_CONFIRMED,
    identifier
  };
};

const closeErrorModal = errorCounter => {
  return dispatch => {
    if (errorCounter >= 3) {
      dispatch(push('/checkout/error'));
    }
    dispatch({
      type: types.CLOSE_ERROR_MODAL
    });
  };
};

const countError = () => {
  return {
    type: types.COUNT_ERROR
  };
};

const setError = error => {
  return {
    type: types.SET_ERROR,
    error
  };
};

const unsetError = error => {
  return {
    type: types.UNSET_ERROR,
    error
  };
};

export default {
  acceptTerms,
  processData,
  acceptPrivacy,
  closeWelcomeModal,
  showSnackbarError,
  hideSnackbarError,
  setProgress,
  setStep,
  setConfirmed,
  closeErrorModal,
  countError,
  setError,
  unsetError
};
