import React, { useEffect } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { tenantActions } from './redux/tenant';
import DefaultProcess from './containers/default-process';

interface IApp {
  location: any;
  history: any;
}
const App: React.FC<IApp> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tenantActions.getTenant());
  }, [dispatch]);

  return (
    <Switch>
      <Route component={DefaultProcess} />
    </Switch>
  );
};

export default withRouter(App);
