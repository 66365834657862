import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from './loader.styles';
import './loader.css';

const useStyles = makeStyles(styles);

interface ILoader {
  isLoading: boolean;
  transparent?: boolean;
  message?: string;
}
const Loader: React.FC<ILoader> = props => {
  const classes = useStyles();
  const { isLoading, transparent, message } = props;
  let loaderClass = classes.Loader;
  if (transparent) {
    loaderClass = classnames(loaderClass, classes.transparent);
  }
  return (
    <>
      {isLoading && (
        <div className={loaderClass}>
          <div className={classes.Spinner}>
            <div className={classes.SpinnerElement} />
          </div>
          {message && (
            <FormattedHTMLMessage id={message}>
              {txt => (
                <span
                  className={classes.message}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    // @ts-ignore
                    __html: txt
                  }}
                />
              )}
            </FormattedHTMLMessage>
          )}
        </div>
      )}
    </>
  );
};

export default Loader;
