import types from './types';

const getTenant = () => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_TENANT
    });

    return api.tenant
      .getTenant(dispatch)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_TENANT,
          tenant: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_TENANT,
          error
        });
      });
  };
};

const getTenants = (zipCode, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_TENANTS
    });

    return api.tenant
      .getTenants(zipCode)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_TENANTS,
          tenants: res
        });
        if (res.length === 0) {
          cb(false);
        }
        cb(res);
      })
      .catch(error => {
        cb(false);
        dispatch({
          type: types.ERROR_GET_TENANTS,
          error
        });
      });
  };
};

export default {
  getTenant,
  getTenants
};
