import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const getInitialState = () => {
  return {
    uuid: null,
    dealState: null,
    processStepsCompleted: [],
    property: {
      address: {
        city: '',
        postalCode: '',
        street: '',
        streetNumber: '',
        state: '',
        isSet: false,
        manual: false
      },
      description: {
        landArea: null,
        livingArea: null,
        year: null
      },
      propertyType: '',
      debtorPostalCode: null
    },
    gateway: {
      checkout: false,
      collectPersonalData: false,
      reason: null
    },
    liabilities: {
      existingLoansWithBank: false,
      totalLoanOverThreshold: false,
      otherExistingLoans: false,
      balanceOverThreshold: false,
      moreLoansPlanned: false
    },
    finished: false,
    documentPw: null,
    places: [],
    apiStatus: {
      createDeal: {},
      createDocuments: {},
      createContract: {},
      getDeal: {},
      checkoutPersonaldata: {
        initial: true
      }
    }
  };
};

const requestCreateDeal = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createDeal: request()
    }
  };
};

const successCreateDeal = (state, action) => {
  const { deal } = action;
  return {
    ...state,
    uuid: deal.uuid,
    apiStatus: {
      ...state.apiStatus,
      createDeal: result()
    }
  };
};

const errorCreateDeal = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createDeal: result(error)
    }
  };
};

const requestCreateContract = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createContract: request()
    }
  };
};

const successCreateContract = (state, action) => {
  const { deal } = action;
  return {
    ...state,
    documentPw: deal.contractPdfUserPassword,
    apiStatus: {
      ...state.apiStatus,
      createContract: result()
    }
  };
};

const errorCreateContract = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createContract: result(error)
    }
  };
};

const requestGetDeal = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getDeal: request()
    }
  };
};

const successGetDeal = (state, action) => {
  const { deal } = action;
  return {
    ...state,
    ...deal,
    apiStatus: {
      ...state.apiStatus,
      getDeal: result()
    }
  };
};

const errorGetDeal = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getDeal: result(error)
    }
  };
};

const gatewayCheckout = (state, action) => {
  const { error } = action;
  return {
    ...state,
    gateway: {
      ...state.gateway,
      ...error
    }
  };
};

const requestCheckoutPersonaldata = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      checkoutPersonaldata: request()
    }
  };
};

const successCheckoutPersonaldata = state => {
  return {
    ...state,
    finished: true,
    apiStatus: {
      ...state.apiStatus,
      checkoutPersonaldata: result()
    }
  };
};

const errorCheckoutPersonaldata = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      checkoutPersonaldata: result(error)
    }
  };
};

const handlers = {
  [types.REQUEST_CREATE_DEAL]: requestCreateDeal,
  [types.SUCCESS_CREATE_DEAL]: successCreateDeal,
  [types.ERROR_CREATE_DEAL]: errorCreateDeal,
  [types.REQUEST_CREATE_CONTRACT]: requestCreateContract,
  [types.SUCCESS_CREATE_CONTRACT]: successCreateContract,
  [types.ERROR_CREATE_CONTRACT]: errorCreateContract,
  [types.REQUEST_GET_DEAL]: requestGetDeal,
  [types.SUCCESS_GET_DEAL]: successGetDeal,
  [types.ERROR_GET_DEAL]: errorGetDeal,
  [types.GATEWAY_CHECKOUT]: gatewayCheckout,
  [types.REQUEST_CHECKOUT_PERSONALDATA]: requestCheckoutPersonaldata,
  [types.SUCCESS_CHECKOUT_PERSONALDATA]: successCheckoutPersonaldata,
  [types.ERROR_CHECKOUT_PERSONALDATA]: errorCheckoutPersonaldata
};
export default createReducer(getInitialState, handlers);
