import { push } from 'connected-react-router';
import settingsTypes from '../settings/types';
import types from './types';
import routes from '../../components/global/routes';
import de from '../../static/translations/de.json';

const createDebtors = (dealUuid, debtors) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CREATE_DEBTOR
    });
    return api.debtor
      .createDebtor(dealUuid, debtors)
      .then(res => {
        dispatch({
          type: types.SUCCESS_CREATE_DEBTOR,
          debtors: res
        });
        dispatch(push('/budget/bank/start'));
      })
      .catch(error => {
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        dispatch({
          type: types.ERROR_CREATE_DEBTOR,
          error
        });
      });
  };
};

const getAccounts = (dealUuid, debtor, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_ACCOUNTS,
      debtor
    });

    return api.debtor
      .getAccounts(dealUuid, debtor.id)
      .then(res => {
        cb(true);
        if (res.declinedAccounts.length < 1 && res.selectedAccounts.length < 1 && res.selectableAccounts.length < 1) {
          cb(false);
        }
        dispatch({
          type: types.SUCCESS_GET_ACCOUNTS,
          accounts: res
        });
      })
      .catch(error => {
        dispatch({
          type: settingsTypes.SNACKBAR_ERROR_SHOW,
          error
        });
        dispatch({
          type: types.ERROR_GET_ACCOUNTS,
          error
        });
      });
  };
};

const getTransactions = (dealUuid, id, bankAccounts, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_TRANSACTIONS,
      id,
      bankAccounts
    });

    const cbObj = {
      result: false,
      accountNumbers: []
    };
    return api.debtor
      .getTransactions(dealUuid, id, bankAccounts)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_TRANSACTIONS,
          debtor: res
        });
        cbObj.result = true;
        cb(cbObj);
        dispatch(
          // push('/budget/accounting'),
          push('/budget/debtor/income')
        );
      })
      .catch(error => {
        switch (error.status) {
          case 422:
            cbObj.result = false;
            cbObj.accountNumbers = error.data.accountNumbers;
            cb(cbObj);
            break;
          case 404:
            dispatch(push('/budget/debtor/more-banks'));
            break;

          default:
            break;
        }
        dispatch({
          type: types.ERROR_GET_TRANSACTIONS,
          error
        });
      });
  };
};

const setDebtors = formdata => {
  return dispatch => {
    dispatch({
      type: types.SET_DEBTORS,
      formdata
    });
  };
};

const selectDebtor = index => {
  return dispatch => {
    // fallback to first debtor, if sth. goes wrong with the parameter
    let number = index;
    if (!number) {
      number = 0;
    }
    dispatch({
      type: types.SELECT_DEBTOR,
      index: number
    });
  };
};

const getIncome = (dealUuid, debtorUuid) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_INCOME
    });

    return api.debtor
      .getIncome(dealUuid, debtorUuid)
      .then(res => {
        if (res.length === 0) {
          dispatch(push(routes.BUDGET_DEBTOR_MORE_BANKS.path));
        }
        dispatch({
          type: types.SUCCESS_GET_INCOME,
          incomeFeedback: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_INCOME,
          error
        });
      });
  };
};

const postIncome = (dealUuid, formdata, debtorUuid, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_POST_INCOME
    });
    return api.debtor
      .postIncome(dealUuid, formdata, debtorUuid)
      .then(() => {
        dispatch({
          type: types.SUCCESS_POST_INCOME
        });
        cb(true);
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_POST_INCOME,
          error
        });
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        cb(false);
      });
  };
};

const postProposal = (dealUuid, formdata) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_POST_PROPOSAL
    });
    return api.debtor
      .postProposal(dealUuid, {
        reference: formdata.reference,
        email: formdata.email || `${formdata.email}${de['app.budget.debtor.proposal.endadornment']}`,
        customerEmail: formdata.customerEmail
      })
      .then(() => {
        dispatch({
          type: types.SUCCESS_POST_PROPOSAL,
          values: formdata
        });
        dispatch(push(routes.BUDGET_DEBTOR_CREATE.path));
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_POST_PROPOSAL,
          error
        });
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
      });
  };
};

export default {
  getAccounts,
  getTransactions,
  createDebtors,
  setDebtors,
  selectDebtor,
  getIncome,
  postIncome,
  postProposal
};
