import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    ' h6': {
      color: '#fff'
    },
    cellLeft: {
      borderBottom: 'none',
      textAlign: 'left'
    },
    cellLeftSummary: {
      borderBottom: 'none',
      textAlign: 'left',
      fontSize: '18px'
    },
    cellRight: {
      borderBottom: 'none',
      textAlign: 'right'
    },
    cellRightSummary: {
      borderBottom: 'none',
      textAlign: 'right',
      fontSize: '18px'
    },
    ModalTableTitle: {
      color: '#ffffff !important'
    },
    startButton: {
      marginTop: '10px'
    },
    Modal: {
      background: theme.color.background.light,
      color: `${theme.color.font.dark} !important`,
      paddingTop: '12px',
      paddingLeft: '36px',
      paddingBottom: '80px'
    },
    ModalTitleWrapper: {
      background: theme.color.background.light,
      color: `${theme.color.main} !important`,
      paddingTop: '36px',
      paddingLeft: '36px'
    },
    ModalTitle: {
      color: `${theme.color.main} !important`,
      fontSize: '28px',
      fontWeight: 'bold',
      paddingTop: '30px'
    },
    closeButton: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      color: theme.color.main,
      background: `${theme.color.background.light} !important`,
      // fontSize: '14px',
      '&:hover': {
        background: 'transparent',
        color: `${theme.color.main} !important`
      }
    },
    imprint: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      marginRight: '30px',
      marginTop: '25px',
      fontSize: '12px',
      zIndex: 9999
    },
    modalContainer: {
      margin: '10px'
    }
  });

export default styles;
