export default {
  REQUEST_CREATE_DEBTOR: 'DEBTOR_REQUEST_CREATE_DEBTOR',
  SUCCESS_CREATE_DEBTOR: 'DEBTOR_SUCCESS_CREATE_DEBTOR',
  ERROR_CREATE_DEBTOR: 'DEBTOR_ERROR_CREATE_DEBTOR',
  REQUEST_GET_ACCOUNTS: 'DEBTOR_REQUEST_GET_ACCOUNTS',
  SUCCESS_GET_ACCOUNTS: 'DEBTOR_SUCCESS_GET_ACCOUNTS',
  ERROR_GET_ACCOUNTS: 'DEBTOR_ERROR_GET_ACCOUNTS',
  REQUEST_GET_TRANSACTIONS: 'DEBTOR_REQUEST_GET_TRANSACTIONS',
  SUCCESS_GET_TRANSACTIONS: 'DEBTOR_SUCCESS_GET_TRANSACTIONS',
  ERROR_GET_TRANSACTIONS: 'DEBTOR_GET_TRANSACTIONS',
  SET_DEBTORS: 'DEBTOR_SET_DEBTORS',
  SELECT_DEBTOR: 'DEBTOR_SELECT_DEBTOR',
  REQUEST_GET_INCOME: 'DEBTOR_REQUEST_GET_INCOME',
  SUCCESS_GET_INCOME: 'DEBTOR_SUCCESS_GET_INCOME',
  ERROR_GET_INCOME: 'DEBTOR_ERROR_GET_INCOME',
  REQUEST_POST_INCOME: 'DEBTOR_REQUEST_POST_INCOME',
  SUCCESS_POST_INCOME: 'DEBTOR_SUCCESS_POST_INCOME',
  ERROR_POST_INCOME: 'DEBTOR_ERROR_POST_INCOME',
  REQUEST_POST_PROPOSAL: 'DEBTOR_REQUEST_POST_PROPOSAL',
  SUCCESS_POST_PROPOSAL: 'DEBTOR_SUCCESS_POST_PROPOSAL',
  ERROR_POST_PROPOSAL: 'DEBTOR_ERROR_POST_PROPOSAL'
};
