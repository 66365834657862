import { createBlacklistFilter } from 'redux-persist-transform-filter';
import tenantReducer from './tenant';
import dealReducer from './deal';
import settingsReducer from './settings';
import loanReducer from './loan';
import debtorReducer from './debtor';
import bankReducer from './bank';
import budgetReducer from './budget';

export default {
  tenant: tenantReducer,
  deal: dealReducer,
  settings: settingsReducer,
  loan: loanReducer,
  debtor: debtorReducer,
  bank: bankReducer,
  budget: budgetReducer
};

const blacklistApiStatus = [
  createBlacklistFilter('tenant', ['apiStatus']),
  createBlacklistFilter('deal', ['apiStatus']),
  createBlacklistFilter('loan', ['apiStatus']),
  createBlacklistFilter('debtor', ['apiStatus']),
  createBlacklistFilter('bank', ['apiStatus']),
  createBlacklistFilter('budget', ['apiStatus'])
];
export { blacklistApiStatus };
