import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const debtorShape = {
  id: null,
  firstName: null,
  lastName: null,
  eMail: null,
  phone: null,
  married: false,
  residentSince: false,
  address: {
    formattedAddress: null,
    street: null,
    streetNumber: null,
    postalCode: null,
    city: null,
    state: null,
    country: null,
    isSet: false,
    location: {
      latitude: null,
      longitude: null
    }
  },
  formerAddress: {
    formattedAddress: null,
    street: null,
    streetNumber: null,
    postalCode: null,
    city: null,
    state: null,
    country: null,
    isSet: false,
    location: {
      latitude: null,
      longitude: null
    }
  },
  schufaBankSecrecyExemption: false,
  ibans: [],
  bankCode: null,
  selectableAccounts: [],
  selectedAccounts: [],
  declinedAccounts: [],
  incomeFeedback: []
};

const getInitialState = () => {
  return {
    debtors: [],
    currentDebtor: 0,
    reference: '',
    email: '',
    customerEmail: '',
    apiStatus: {
      createDebtor: {},
      createAccess: {},
      getAccounts: {},
      getTransactions: {},
      getIncomeFeedback: {},
      postIncomeFeedback: {},
      postProposal: {}
    }
  };
};

const requestCreateDebtor = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createDebtor: request()
    }
  };
};

const successCreateDebtor = (state, action) => {
  const updateDebtors = state.debtors;
  const { debtors } = action;
  if (debtors[0]) {
    updateDebtors[0].id = debtors[0].debtorUUID;
  }
  if (debtors[1]) {
    updateDebtors[1].id = debtors[1].debtorUUID;
  }
  return {
    ...state,
    currentDebtor: 0,
    debtors: updateDebtors,
    apiStatus: {
      ...state.apiStatus,
      createDebtor: result()
    }
  };
};

const errorCreateDebtor = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      createDebtor: result(error)
    }
  };
};

const requestGetAccounts = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccounts: request()
    }
  };
};

const successGetAccounts = (state, action) => {
  const { accounts } = action;
  const { debtors } = state;
  debtors[state.currentDebtor] = {
    ...debtors[state.currentDebtor],
    ...accounts
  };
  return {
    ...state,
    debtors,
    apiStatus: {
      ...state.apiStatus,
      getAccounts: result()
    }
  };
};

const errorGetAccounts = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccounts: result(error)
    }
  };
};

const requestGetTransactions = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTransactions: request()
    }
  };
};

const successGetTransactions = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTransactions: result()
    }
  };
};

const errorGetTransactions = (state, action) => {
  const { error } = action.error;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTransactions: result(error)
    }
  };
};

const setDebtors = (state, action) => {
  const { formdata } = action;
  const debtors = [];
  formdata.map(item => {
    const debtor = {
      ...debtorShape,
      ...item
    };
    if (item) {
      debtors.push(debtor);
    }
    return true;
  });
  return {
    ...state,
    debtors
  };
};

const selectDebtor = (state, action) => {
  const { index } = action;
  return {
    ...state,
    currentDebtor: index
  };
};

const requestGetIncomeFeedback = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getIncomeFeedback: request()
    }
  };
};

const successGetIncomeFeedback = (state, action) => {
  const { incomeFeedback } = action;
  const { debtors } = state;
  debtors[state.currentDebtor] = {
    ...debtors[state.currentDebtor],
    incomeFeedback
  };
  return {
    ...state,
    debtors,
    apiStatus: {
      ...state.apiStatus,
      getIncomeFeedback: result()
    }
  };
};

const errorGetIncomeFeedback = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getIncomeFeedback: result(error)
    }
  };
};

const requestPostIncomeFeedback = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postIncomeFeedback: request()
    }
  };
};

const successPostIncomeFeedback = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postIncomeFeedback: result()
    }
  };
};

const errorPostIncomeFeedback = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postIncomeFeedback: result(error)
    }
  };
};

const requestPostProposal = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postProposal: request()
    }
  };
};

const successPostProposal = (state, action) => {
  const { values } = action;
  return {
    ...state,
    reference: values.reference,
    email: values.email,
    customerEmail: values.customerEmail,
    apiStatus: {
      ...state.apiStatus,
      postProposal: result()
    }
  };
};

const errorPostProposal = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      postProposal: result(error)
    }
  };
};

const handlers = {
  [types.REQUEST_CREATE_DEBTOR]: requestCreateDebtor,
  [types.SUCCESS_CREATE_DEBTOR]: successCreateDebtor,
  [types.ERROR_CREATE_DEBTOR]: errorCreateDebtor,
  [types.REQUEST_GET_ACCOUNTS]: requestGetAccounts,
  [types.SUCCESS_GET_ACCOUNTS]: successGetAccounts,
  [types.ERROR_GET_ACCOUNTS]: errorGetAccounts,
  [types.REQUEST_GET_TRANSACTIONS]: requestGetTransactions,
  [types.SUCCESS_GET_TRANSACTIONS]: successGetTransactions,
  [types.ERROR_GET_TRANSACTIONS]: errorGetTransactions,
  [types.SET_DEBTORS]: setDebtors,
  [types.SELECT_DEBTOR]: selectDebtor,
  [types.REQUEST_GET_INCOME]: requestGetIncomeFeedback,
  [types.SUCCESS_GET_INCOME]: successGetIncomeFeedback,
  [types.ERROR_GET_INCOME]: errorGetIncomeFeedback,
  [types.REQUEST_POST_INCOME]: requestPostIncomeFeedback,
  [types.SUCCESS_POST_INCOME]: successPostIncomeFeedback,
  [types.ERROR_POST_INCOME]: errorPostIncomeFeedback,
  [types.REQUEST_POST_PROPOSAL]: requestPostProposal,
  [types.SUCCESS_POST_PROPOSAL]: successPostProposal,
  [types.ERROR_POST_PROPOSAL]: errorPostProposal
};
export default createReducer(getInitialState, handlers);
