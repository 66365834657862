import React, { useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, makeStyles, withMobileDialog } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import styles from './welcome-modal.styles';

const useStyles = makeStyles(styles);

class PrintComponent extends React.Component {
  render() {
    // @ts-ignore
    const { content } = this.props;
    const printContent = content as any;
    return printContent;
  }
}

interface IModal {
  open: boolean;
  fullScreen: boolean;
  onClose: any;
  content: any;
  headline: any;
}
const Modal: React.FC<IModal> = props => {
  const { onClose, content, open, fullScreen } = props;
  const classes = useStyles();
  const componentRef = useRef() as any;

  return (
    <Dialog open={open} maxWidth="md" fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title" className={classes.ModalTitleWrapper}>
        <ReactToPrint
          trigger={() => (
            <IconButton
              aria-label="Close"
              size="small"
              style={{
                position: 'absolute',
                right: '64px',
                top: '24px'
              }}
            >
              <PrintIcon fontSize="inherit" />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
        <div style={{ display: 'none' }}>
          <PrintComponent
            // @ts-ignore
            content={content}
            ref={componentRef}
          />
        </div>
        <IconButton aria-label="Close" size="medium" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.Modal}>{content}</DialogContent>
    </Dialog>
  );
};

export default withMobileDialog()(Modal);
