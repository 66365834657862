export default function bank(apiWrapper) {
  const { REACT_APP_SEARCH_QUERY_PARAM } = process.env;
  const searchBank = (dealUuid, searchString) => {
    return apiWrapper.fetchUnauthorized({
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      endpoint: `/bank/?${REACT_APP_SEARCH_QUERY_PARAM}=${searchString}`
    });
  };

  const createAccess = (dealUuid, debtorId, bankObj) => {
    const payload = {
      accessMethodId: bankObj.accessMethods[0].id,
      bankCode: bankObj.bankCode
    };

    if (bankObj.accessMethods[0].requiresAccountIdentifiers) {
      payload.ibans = bankObj.accessMethods[0].ibans;
    }

    if (bankObj.accessMethods[0].customerAuthenticationFlows[0] !== 'REDIRECT') {
      payload.credentials = bankObj.credentials;
    } else {
      payload.redirectURL = `${window.location.href}-sync`;
    }

    const payloadWithoutCreds = { ...payload };
    delete payloadWithoutCreds.credentials;

    return apiWrapper.fetchUnauthorized({
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      endpoint: `/debtors/${debtorId}/accesses`,
      method: 'POST',
      payload: payloadWithoutCreds
    });
  };

  const postAccessLoginSync = (dealUuid, id, accessId, credentials) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${id}/accesses/${accessId}/sync`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: {
        credentials,
        redirectURL: `${window.location.href}`
      }
    });
  };

  const getAccessSyncStatus = (dealUuid, id, accessId) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${id}/accesses/${accessId}`,
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const selectAuthMethod = (dealUuid, id, accessId, challenge) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${id}/accesses/${accessId}/challenge`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: {
        ...challenge
      }
    });
  };

  const startAccess = (dealUuid, debtorId, useScenario) => {
    const isDev = process.env.REACT_APP_FINLEAP_DEV ? '?dev=true' : '';
    const connector = isDev ? '&' : '?';
    const isScenario = useScenario ? `${connector}scenario=true` : '';
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${debtorId}/access${isDev}${isScenario}`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const startScenario = (dealUuid, url) => {
    return apiWrapper.fetchUnauthorized({
      totalUrl: url,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const getScenarios = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/manualcheck/scenarios',
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const getToken = (dealUuid, debtorId, token, state, useScenario) => {
    const isDev = process.env.REACT_APP_FINLEAP_DEV ? '&dev=true' : '';
    const isScenario = useScenario ? `&scenario=true` : '';
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${debtorId}/token?code=${token}&state=${state}${isDev}${isScenario}`,
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  return {
    getScenarios,
    searchBank,
    createAccess,
    getAccessSyncStatus,
    selectAuthMethod,
    postAccessLoginSync,
    startAccess,
    startScenario,
    getToken
  };
}
