import axios from 'axios';

const classifyErrorCode = status => {
  switch (status) {
    case 422:
      return 'api.error.gatewayCriterion';
    default:
      return 'api.error.unknown';
  }
};

class ApiError extends Error {
  constructor(err, ...params) {
    super(params);
    this.id = classifyErrorCode(err.status);
    this.status = err.status;
    this.checkout = err.data.checkout || false;
    this.collectPersonalData = err.data.collectPersonalData || false;
    this.gatewayReason = err.data.gateway || null;
    this.data = err.data || null;
  }
}

const handleApiError = err => {
  throw new ApiError(err);
};

const fetchUnauthorized = req => {
  return axios({
    method: req.method,
    url: req.totalUrl ? `${req.totalUrl}` : `${process.env.REACT_APP_API_URL}${req.endpoint}`,
    headers: {
      ...req.headers,
      'X-API-KEY': window.env.API_KEY
    },
    data: req.payload
  })
    .then(response => response.data)
    .catch(err => handleApiError(err.response));
};

const fetchAuthorized = req => {
  return axios({
    method: req.method,
    url: `${process.env.REACT_APP_API_URL}${req.endpoint}`,
    headers: {
      ...req.headers,
      'X-API-KEY': window.env.API_KEY,
      authorization: `Bearer ${localStorage.getItem('jwt')}`
    },
    data: req.payload
  })
    .then(response => response.data)
    .catch(err => handleApiError(err));
};

export default {
  fetchUnauthorized,
  fetchAuthorized
};

export { ApiError };
