import { push } from 'connected-react-router';
import types from './types';
import settingsTypes from '../settings/types';
import loanTypes from '../loan/types';

const createDeal = () => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CREATE_DEAL
    });

    return api.deal
      .createDeal()
      .then(res => {
        dispatch({
          type: types.SUCCESS_CREATE_DEAL,
          deal: res
        });
        dispatch({
          type: settingsTypes.CLOSE_WELCOME_MODAL
        });
      })
      .catch(error => {
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        dispatch({
          type: types.ERROR_CREATE_DEAL,
          error
        });
      });
  };
};

const createContract = (dealUuid, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CREATE_CONTRACT
    });

    return api.deal
      .createContract(dealUuid)
      .then(res => {
        dispatch({
          type: types.SUCCESS_CREATE_CONTRACT,
          deal: res
        });
        cb(true);
        const interhypRedirectUrl = sessionStorage.getItem('interhypRedirectUrl');

        if (interhypRedirectUrl && interhypRedirectUrl !== null && interhypRedirectUrl !== '') {
          window.location.href = `${interhypRedirectUrl}?status=OK`;
        } else {
          dispatch(push('/checkout/confirmation'));
        }
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_CREATE_CONTRACT,
          error
        });
        if (error.checkout) {
          dispatch({
            type: types.GATEWAY_CHECKOUT,
            error
          });
          const interhypRedirectUrl = sessionStorage.getItem('interhypRedirectUrl');

          if (interhypRedirectUrl && interhypRedirectUrl !== null && interhypRedirectUrl !== '') {
            window.location.href = `${interhypRedirectUrl}?status=OK`;
          } else {
            dispatch(push('/checkout/cancel'));
          }
        } else {
          cb(false);
        }
        if (error.status === 504) {
          dispatch(push('/checkout/processing'));
        } else {
          cb(false);
        }
      });
  };
};

const checkoutPersonaldata = (dealUuid, formdata, debtorUuid, collectPersonalData, successful) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CHECKOUT_PERSONALDATA
    });
    return api.deal
      .checkoutPersonalData(dealUuid, formdata, debtorUuid, collectPersonalData)
      .then(() => {
        dispatch({
          type: types.SUCCESS_CHECKOUT_PERSONALDATA
        });
        if (successful) {
          dispatch(push('/checkout/confirmation'));
        } else {
          dispatch(push('/checkout/cancel'));
        }
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_CHECKOUT_PERSONALDATA,
          error
        });
      });
  };
};

const getDeal = (dealUuid, processStepsCompleted) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_DEAL
    });

    return api.deal
      .getDeal(dealUuid)
      .then(res => {
        const difference = processStepsCompleted.filter(x => !res.processStepsCompleted.includes(x));
        dispatch({
          type: types.SUCCESS_GET_DEAL,
          deal: res
        });

        if (difference.includes('FINANCING_NEED')) {
          dispatch({
            type: loanTypes.RESET_LOAN_ESTIMATOR
          });
        }

        if (difference.includes('LOAN_AMORTIZATION_SUMMARY')) {
          dispatch({
            type: loanTypes.RESET_CREDIT_MODEL
          });
        }
      })
      .catch(error => {
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        dispatch({
          type: types.ERROR_GET_DEAL,
          error
        });
      });
  };
};

const getDealNumber = dealUuid => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_DEAL
    });

    return api.deal
      .getDeal(dealUuid)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_DEAL,
          deal: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_DEAL,
          error
        });
      });
  };
};

const checkContract = (dealUuid, cb) => {
  return (dispatch, _getState, api) => {
    return api.deal
      .checkContract(dealUuid)
      .then(res => {
        const { available } = res;

        if (available) {
          cb(true);
        } else {
          cb(false);
        }
      })
      .catch(() => {
        cb(false);
      });
  };
};

export default {
  createDeal,
  createContract,
  getDeal,
  getDealNumber,
  checkoutPersonaldata,
  checkContract
};
