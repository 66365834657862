import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    Footer: {
      flexShrink: 0,
      background: theme.color.background.lightGrey,
      paddingTop: '4px',
      paddingBottom: '4px',
      textAlign: 'center',
      position: 'relative',
      width: '100%',
      height: '94px'
    },
    FooterElement: {
      padding: theme.responseSpacing.unit
    },
    footerItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    footerMenu: {
      textAlign: 'left',
      paddingLeft: '140px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '40px'
      }
    },
    logo: {
      height: '42px',
      [theme.breakpoints.down('md')]: {
        height: '26px'
      }
    }
  });

export default styles;
