import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { IRootStore } from '../../typings/interfaces';
import { settingsActions } from '../../redux/settings';
import Modal from '../modal';
import ActionButton from '../action-button';

const ErrorModal: React.FC = () => {
  const settings = useSelector((state: IRootStore) => state.settings);
  const dispatch = useDispatch();

  return (
    <Modal
      open={settings.showErrorModal}
      isClosable={false}
      content={<FormattedMessage id="app.errorModal.description" />}
      actions={
        <ActionButton title="OK" action={() => dispatch(settingsActions.closeErrorModal(settings.errorCounter))} />
      }
    />
  );
};

export default ErrorModal;
