export default function deal(apiWrapper) {
  const createDeal = token => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/deal',
      method: 'POST',
      payload: {
        userToken: token
      }
    });
  };

  const createContract = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/deal/contract',
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const getDeal = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/deal',
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const checkoutPersonalData = (dealUuid, formdata, debtorUUID, collectPersonalData) => {
    const payload = formdata;
    if (payload.contactPreference === 'PHONE') {
      delete payload.eMail;
    }

    if (!collectPersonalData) {
      return apiWrapper.fetchUnauthorized({
        endpoint: `/debtors/${debtorUUID}/person`,
        method: 'PATCH',
        headers: {
          'X-MHB-DEAL-UUID': dealUuid
        },
        payload
      });
    }
    return apiWrapper.fetchUnauthorized({
      endpoint: '/checkout',
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload
    });
  };

  const checkContract = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/deal/${dealUuid}/contract/docsavailable`,
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  return {
    createDeal,
    createContract,
    getDeal,
    checkoutPersonalData,
    checkContract
  };
}
