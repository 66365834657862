export default function budget(apiWrapper) {
  const getAccounting = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/budget/',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      method: 'GET'
    });
  };
  const putFeedback = (dealUuid, formdata) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/budget/',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      method: 'PATCH',
      payload: formdata
    });
  };
  const getLiabilities = dealUuid => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/budget/liability',
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };
  const confirmBudgetAccounting = (dealUuid, formdata) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/budget/confirm',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      method: 'POST',
      payload: formdata
    });
  };

  return {
    getAccounting,
    putFeedback,
    getLiabilities,
    confirmBudgetAccounting
  };
}
