import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const getInitialState = () => {
  return {
    income: {
      sum: 0,
      childBenefit: 0,
      salary: 0,
      unemploymentBenefit: 0,
      pension: 0
    },
    expenditure: {
      sum: 0,
      otherExpenses: 0,
      rents: 0,
      savings: 0,
      costOfLiving: 0,
      insurance: 0,
      healthInsurance: 0,
      financing: 0,
      vehicleCosts: 0,
      transferPayments: 0,
      taxesFeeDonation: 0
    },
    feedback: {
      rents: [],
      savings: [],
      persons: null,
      periodicPayments: [],
      insurances: [],
      highExpenses: [],
      confirmed: false
    },
    confirmed: false,
    budgetAccountConfirmation: null,
    liabilities: [],
    apiStatus: {
      getAccounting: {},
      putFeedback: {},
      confirmBudget: {},
      getLiabilities: {}
    }
  };
};

const requestGetAccounting = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccounting: request()
    }
  };
};

const successGetAccounting = (state, action) => {
  const { budget } = action;
  return {
    ...state,
    income: budget.income,
    expenditure: budget.expenditure,
    feedback: budget.feedback,
    budgetAccountConfirmation: budget.budgetAccountConfirmation,
    apiStatus: {
      ...state.apiStatus,
      getAccounting: result()
    }
  };
};

const errorGetAccounting = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAccounting: result(error)
    }
  };
};

const requestPutFeedback = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      putFeedback: request()
    }
  };
};

const successPutFeedback = (state, action) => {
  const { budget } = action;
  return {
    ...state,
    feedback: {
      ...state.feedback,
      ...budget.feedback
    },
    apiStatus: {
      ...state.apiStatus,
      putFeedback: result()
    }
  };
};

const errorPutFeedback = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      putFeedback: result(error)
    }
  };
};

const requestGetLiabilities = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getLiabilities: request()
    }
  };
};

const successGetLiabilities = (state, action) => {
  const { liabilities } = action;
  return {
    ...state,
    liabilities,
    apiStatus: {
      ...state.apiStatus,
      getLiabilities: result()
    }
  };
};

const errorGetLiabilities = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getLiabilities: result(error)
    }
  };
};

const requestConfirmBudget = state => {
  return {
    ...state,
    confirmed: true,
    apiStatus: {
      ...state.apiStatus,
      confirmBudget: request()
    }
  };
};

const successConfirmBudget = state => {
  return {
    ...state,
    confirmed: true,
    apiStatus: {
      ...state.apiStatus,
      confirmBudget: result()
    }
  };
};

const errorConfirmBudget = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      confirmBudget: result(error)
    }
  };
};

const handlers = {
  [types.REQUEST_GET_BUDGET_ACCOUNTING]: requestGetAccounting,
  [types.SUCCESS_GET_BUDGET_ACCOUNTING]: successGetAccounting,
  [types.ERROR_GET_BUDGET_ACCOUNTING]: errorGetAccounting,
  [types.REQUEST_PUT_FEEDBACK]: requestPutFeedback,
  [types.SUCCESS_PUT_FEEDBACK]: successPutFeedback,
  [types.ERROR_PUT_FEEDBACK]: errorPutFeedback,
  [types.REQUEST_GET_LIABILITIES]: requestGetLiabilities,
  [types.SUCCESS_GET_LIABILITIES]: successGetLiabilities,
  [types.ERROR_GET_LIABILITIES]: errorGetLiabilities,
  [types.REQUEST_CONFIRM_BUDGET_ACCOUNTING]: requestConfirmBudget,
  [types.SUCCESS_CONFIRM_BUDGET_ACCOUNTING]: successConfirmBudget,
  [types.ERROR_CONFIRM_BUDGET_ACCOUNTING]: errorConfirmBudget
};
export default createReducer(getInitialState, handlers);
