import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { tenantActions } from '../../redux/tenant';
import { dealActions } from '../../redux/deal';
import { settingsActions } from '../../redux/settings';
import Footer from '../../components/footer';
import Navbar from '../../components/navbar';
import ErrorModal from '../../components/error-modal';
import Router from './router';
import WelcomeModal from '../../components/welcome-modal';
import SnackbarError from '../../components/snackbar-error';
import styles from '../../styles';
import routes from '../../components/global/routes';
import { IRootStore } from '../../typings/interfaces';

const useStyles = makeStyles(styles);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface IDefaultProcess {
  location: any;
}
const DefaultProcess: React.FC<IDefaultProcess> = props => {
  const { location } = props;

  const query = useQuery();
  const reference = query.get('antragsNr');
  const beraterMail = query.get('email');
  const interhypRedirectUrl = query.get('redirectURL');

  const classes = useStyles();
  const dispatch = useDispatch();

  const settings = useSelector((state: IRootStore) => state.settings);
  const tenant = useSelector((state: IRootStore) => state.tenant);
  const deal = useSelector((state: IRootStore) => state.deal);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const checkDealStatus = () => {
    if (!deal.uuid) {
      dispatch(
        push(`/budget/debtor/proposal?antragsNr=${reference}&email=${beraterMail}&redirectURL=${interhypRedirectUrl}`)
      );
    }
  };

  useEffect(() => {
    checkDealStatus();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (deal.uuid) {
      dispatch(dealActions.getDeal(deal.uuid, deal.processStepsCompleted));
    }
    /* eslint-disable-next-line */
  }, [location.pathname]);

  useEffect(() => {
    dispatch(tenantActions.getTenant());
  }, [dispatch]);

  useEffect(() => {
    let currentStep = 1;
    Object.keys(routes).map(key => {
      // @ts-ignore
      if (routes[key].path === location.pathname) {
        // @ts-ignore
        currentStep = routes[key].step;
      }
      return true;
    });
    if (settings.step !== currentStep) {
      dispatch(settingsActions.setStep(currentStep));
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const { baseData } = tenant;
  return (
    <>
      <Navbar uuid={deal.uuid} tenant={tenant} />
      <SnackbarError />
      <ErrorModal />
      <div className={classes.Viewport}>
        <Router />
      </div>
      {isDesktop && (
        <div className={classes.LogoBar}>
          <img src={baseData.logo} alt="logo" className={classes.tenantLogo} width="auto" />
        </div>
      )}
      <Footer />
      <WelcomeModal settings={settings} tenant={tenant} />
    </>
  );
};

export default withRouter(DefaultProcess);
