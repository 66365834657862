import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    backwardButton: {
      background: theme.color.secondary,
      minWidth: '48px',
      color: theme.color.font.light,
      '&:hover': {
        background: theme.color.secondaryVariant
      },
      '&:disabled': {
        background: '#c0c0c0',
        cursor: 'not-allowed'
      }
    },
    backwardButtonIcon: {
      position: 'absolute',
      right: '12px',
      top: '12px'
    }
  });

export default styles;
