import { push } from 'connected-react-router';
import settingsTypes from '../settings/types';
import types from './types';

const searchBank = (dealUuid, searchString) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_SEARCH_BANK,
      searchString
    });

    return api.bank
      .searchBank(dealUuid, searchString)
      .then(res => {
        dispatch({
          type: types.SUCCESS_SEARCH_BANK,
          bank: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_SEARCH_BANK,
          error
        });
      });
  };
};

const setSelectedBank = bank => {
  return dispatch => {
    dispatch({
      type: types.SET_SELECTED_BANK,
      bank
    });
  };
};

const resetBank = () => {
  return dispatch => {
    dispatch({
      type: types.RESET_BANK
    });
  };
};

const startAccess = (dealUuid, debtorId, useScenario) => {
  return (dispatch, _getState, api) => {
    return api.bank.startAccess(dealUuid, debtorId, useScenario).then(async res => {
      window.location = res.url;
      return true;
    });
  };
};

const getScenarios = dealUuid => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_SCENARIOS
    });

    return api.bank
      .getScenarios(dealUuid)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_SCENARIOS,
          scenarios: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_SCENARIOS,
          error
        });
      });
  };
};

const getToken = (dealUuid, debtorId, token, state, useScenario) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_TOKEN
    });

    return api.bank
      .getToken(dealUuid, debtorId, token, state, useScenario)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_TOKEN,
          res
        });
        dispatch(push('/budget/debtor/income'));
      })
      .catch(error => {
        dispatch(push('/budget/bank/start'));
        dispatch({
          type: settingsTypes.SET_ERROR,
          error: {
            name: 'getToken',
            meta: error.data && error.data.accountNumbers
          }
        });
        dispatch({
          type: types.ERROR_GET_TOKEN,
          error
        });
      });
  };
};

const setIbans = (ibans, bank) => {
  return dispatch => {
    dispatch({
      type: types.SET_IBANS,
      ibans
    });
    if (bank.accessMethods[0].customerAuthenticationFlow === 'REDIRECT') {
      dispatch(push('/budget/bank/access'));
    } else {
      dispatch(push('/budget/bank/login'));
    }
  };
};

const setCredentials = credentials => {
  return dispatch => {
    dispatch({
      type: types.SET_CREDENTIALS,
      credentials
    });
    dispatch(push('/budget/bank/access'));
  };
};

const createAccess = (dealUuid, debtorId, bank) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CREATE_ACCESS
    });
    return api.bank
      .createAccess(dealUuid, debtorId, bank)
      .then(res => {
        dispatch({
          type: types.SUCCESS_CREATE_ACCESS,
          bank: res
        });
        if (res.status === 'COMPLETED') {
          dispatch(push('/budget/debtor/accounts'));
        } else {
          dispatch(push('/budget/bank/access-login-sync'));
        }
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_CREATE_ACCESS,
          error
        });
        dispatch(push('/budget/bank/login'));
      });
  };
};

const getAccessSyncStatus = (dealUuid, debtorId, bank) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_ACCESS_SYNC_STATUS
    });
    return api.bank
      .getAccessSyncStatus(dealUuid, debtorId, bank.accessId)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_ACCESS_SYNC_STATUS,
          bank: res
        });
        switch (res.status) {
          case 'AWAIT_AUTH':
            if (res.challenge) {
              if (res.challenge.type === 'DECOUPLED') {
                dispatch(push('/budget/bank/access-sync'));
              }
            }
            break;

          case 'AWAIT_SYNC':
            dispatch(push('/budget/bank/access-sync'));
            break;

          case 'COMPLETED':
            dispatch(push('/budget/debtor/accounts'));
            break;

          case 'RUNNING':
            dispatch(push('/budget/bank/access-sync'));
            break;

          case 'FAILED':
            dispatch({
              type: settingsTypes.SNACKBAR_ERROR_SHOW,
              error: {
                id: 'app.bank.auth.failed'
              }
            });
            if (bank.accessMethods[0].customerAuthenticationFlows[0] === 'REDIRECT') {
              dispatch(push('/budget/bank/search'));
            } else {
              dispatch(push('/budget/bank/login'));
            }
            break;

          case 'QUEUED':
            dispatch(push('/budget/bank/access-sync'));
            break;

          default:
            break;
        }
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_ACCESS_SYNC_STATUS,
          error
        });
      });
  };
};

const selectAuthMethod = (dealUuid, debtorId, bank, challenge) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_SELECT_AUTH_METHOD
    });
    return api.bank
      .selectAuthMethod(dealUuid, debtorId, bank.accessId, challenge)
      .then(res => {
        dispatch({
          type: types.SUCCESS_SELECT_AUTH_METHOD,
          bank: res
        });
        dispatch(push('/budget/bank/access-sync'));
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_SELECT_AUTH_METHOD,
          error
        });
      });
  };
};

const postAccessLoginSync = (dealUuid, debtorId, bank) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_POST_ACCESS_LOGIN_SYNC
    });
    return api.bank
      .postAccessLoginSync(dealUuid, debtorId, bank.accessId, bank.credentials)
      .then(res => {
        dispatch({
          type: types.SUCCESS_POST_ACCESS_LOGIN_SYNC
        });
        switch (res.status) {
          case 'AWAIT_AUTH':
            dispatch(push('/budget/bank/access-sync'));
            break;

          case 'COMPLETED':
            dispatch(push('/budget/debtor/accounts'));
            break;

          case 'FAILED':
            dispatch({
              type: settingsTypes.SNACKBAR_ERROR_SHOW,
              error: {
                id: 'app.bank.auth.failed'
              }
            });
            if (bank.accessMethods[0].customerAuthenticationFlows[0] === 'REDIRECT') {
              dispatch(push('/budget/bank/search'));
            } else {
              dispatch(push('/budget/bank/login'));
            }
            break;

          default:
            dispatch(push('/budget/bank/access-sync'));
            break;
        }
      })
      .catch(error => {
        if (error.status === 504) {
          dispatch(push('/budget/bank/access-sync'));
        } else {
          dispatch(push('/budget/bank/login'));
        }
        dispatch({
          type: types.ERROR_POST_ACCESS_LOGIN_SYNC,
          error
        });
      });
  };
};

export default {
  getScenarios,
  searchBank,
  setSelectedBank,
  resetBank,
  setIbans,
  createAccess,
  setCredentials,
  getAccessSyncStatus,
  selectAuthMethod,
  postAccessLoginSync,
  startAccess,
  getToken
};
