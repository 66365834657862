export default {
  ACCEPT_TERMS: 'SETTINGS_ACCEPT_TERMS',
  PROCESS_DATA: 'SETTINGS_PROCESS_DATA',
  ACCEPT_PRIVACY: 'SETTINGS_ACCEPT_PRIVACY',
  CLOSE_WELCOME_MODAL: 'SETTINGS_CLOSE_WELCOME_MODAL',
  SNACKBAR_ERROR_SHOW: 'SETTINGS_SNACKBAR_ERROR_SHOW',
  SNACKBAR_ERROR_HIDE: 'SETTINGS_SNACKBAR_ERROR_HIDE',
  SET_PROGRESS: 'SETTINGS_SET_PROGRESS',
  SET_STEP: 'SETTINGS_SET_STEP',
  SET_CONFIRMED: 'SETTINGS_SET_CONFIRMED',
  CLOSE_ERROR_MODAL: 'SETTINGS_CLOSE_ERROR_MODAL',
  COUNT_ERROR: 'SETTINGS_COUNT_ERROR',
  SET_ERROR: 'SETTINGS_SET_ERROR',
  UNSET_ERROR: 'SETTINGS_UNSET_ERROR'
};
