import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    Headline: {
      color: theme.color.main,
      fontSize: theme.fontSize.headline.lg,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.fontSize.headline.md
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.fontSize.headline.xs
      },
      display: 'block',
      marginTop: '10px',
      marginBottom: '10px'
    },
    Subtext: {
      color: theme.color.main,
      fontSize: theme.fontSize.subHeadline.lg,
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
        marginBottom: '4px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.fontSize.subHeadline.xs
      },
      display: 'block',
      marginTop: '20px',
      marginBottom: '20px',
      height: 'auto'
    },
    inversed: {
      color: theme.color.font.light
    },
    hintButton: {
      color: theme.color.secondary
    },
    hintPopover: {
      background: theme.color.main,
      padding: 20,
      width: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '300px'
      }
    },
    hintText: {
      color: theme.color.font.light,
      fontSize: '14px',
      display: 'block'
    }
  });

export default styles;
