export default {
  REQUEST_GET_BUDGET_ACCOUNTING: 'BUDGET_REQUEST_GET_BUDGET_ACCOUNTING',
  SUCCESS_GET_BUDGET_ACCOUNTING: 'BUDGET_SUCCESS_GET_BUDGET_ACCOUNTING',
  ERROR_GET_BUDGET_ACCOUNTING: 'BUDGET_ERROR_GET_BUDGET_ACCOUNTING',
  REQUEST_PATCH_BUDGET_ACCOUNTING: 'BUDGET_REQUEST_PATCH_BUDGET_ACCOUNTING',
  SUCCESS_PATCH_BUDGET_ACCOUNTING: 'BUDGET_SUCCESS_PATCH_BUDGET_ACCOUNTING',
  ERROR_PATCH_BUDGET_ACCOUNTING: 'BUDGET_ERROR_PATCH_BUDGET_ACCOUNTING',
  REQUEST_PUT_FEEDBACK: 'BUDGET_REQUEST_PUT_FEEDBACK',
  SUCCESS_PUT_FEEDBACK: 'BUDGET_SUCCESS_PUT_FEEDBACK',
  ERROR_PUT_FEEDBACK: 'BUDGET_ERROR_PUT_FEEDBACK',
  REQUEST_GET_LIABILITIES: 'BUDGET_REQUEST_GET_LIABILITIES',
  SUCCESS_GET_LIABILITIES: 'BUDGET_SUCCESS_GET_LIABILITIES',
  ERROR_GET_LIABILITIES: 'BUDGET_ERROR_GET_LIABILITIES',
  REQUEST_CONFIRM_BUDGET_ACCOUNTING: 'BUDGET_REQUEST_CONFIRM_BUDGET_ACCOUNTING',
  SUCCESS_CONFIRM_BUDGET_ACCOUNTING: 'BUDGET_SUCCESS_CONFIRM_BUDGET_ACCOUNTING',
  ERROR_CONFIRM_BUDGET_ACCOUNTING: 'BUDGET_ERROR_CONFIRM_BUDGET_ACCOUNTING'
};
