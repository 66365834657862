const request = () => {
  return {
    initial: true,
    isFetching: true,
    hasError: false,
    lastError: null
  };
};

const result = error => {
  const resultObj = {
    initial: false,
    isFetching: false,
    lastError: null,
    hasError: false
  };

  if (error) {
    resultObj.lastError = error.id;
    resultObj.hasError = true;
    resultObj.initial = true;
  }

  return resultObj;
};

export { request, result };
