export default function debtor(apiWrapper) {
  const createDebtor = (dealUuid, debtors) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/debtors',
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: debtors
    });
  };

  const getAccounts = (dealUuid, id) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${id}/accounts`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const getTransactions = (dealUuid, id, bankAccounts) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${id}/transactions`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: bankAccounts
    });
  };

  const postDebtor = (dealUuid, _debtor) => {
    const currentDebtor = { ..._debtor };
    const married = currentDebtor.married === 'true';
    currentDebtor.married = married;
    if (!currentDebtor.formerAddress.isSet) {
      delete currentDebtor.formerAddress;
    }
    return apiWrapper.fetchUnauthorized({
      endpoint: `/debtors/${currentDebtor.id}/person`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: currentDebtor
    });
  };

  const getIncome = (dealUuid, debtorUuid) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/deal/${dealUuid}/incomeFeedbacks/${debtorUuid}`,
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const postIncome = (dealUuid, formdata, debtorUuid) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/deal/${dealUuid}/incomeFeedbacks/${debtorUuid}`,
      method: 'POST',
      payload: formdata,
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const postProposal = (dealUuid, formdata) => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/deal/thirdparty/`,
      method: 'POST',
      payload: formdata,
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  return {
    createDebtor,
    getAccounts,
    getTransactions,
    postDebtor,
    getIncome,
    postIncome,
    postProposal
  };
}
