import { push } from 'connected-react-router';
import settingsTypes from '../settings/types';
import types from './types';

const getAccounting = (dealUuid, skip = false, skipUrl = '') => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_BUDGET_ACCOUNTING
    });

    return api.budget
      .getAccounting(dealUuid)
      .then(res => {
        if (skip) {
          const { feedback } = res;

          let skipBudgetAccounting = true;
          if (feedback.periodicPayments && feedback.periodicPayments.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.rents && feedback.rents.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.loans && feedback.loans.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.savings && feedback.savings.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.insurances && feedback.insurances.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.highExpenses && feedback.highExpenses.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.vehicleFeedback && feedback.vehicleFeedback.vehicleExpenses.length > 0) {
            skipBudgetAccounting = false;
          }
          if (feedback.loans && feedback.loans.length > 0) {
            skipBudgetAccounting = false;
          }

          if (skipBudgetAccounting) {
            dispatch(push(skipUrl));
          }
        }

        dispatch({
          type: types.SUCCESS_GET_BUDGET_ACCOUNTING,
          budget: res
        });
      })
      .catch(error => {
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        dispatch({
          type: types.ERROR_GET_BUDGET_ACCOUNTING,
          error
        });
      });
  };
};

const putFeedback = (dealUuid, formdata, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_PUT_FEEDBACK,
      formdata
    });

    return api.budget
      .putFeedback(dealUuid, formdata, cb)
      .then(res => {
        dispatch({
          type: types.SUCCESS_PUT_FEEDBACK,
          budget: res
        });
      })
      .then(() => {
        cb(true);
      })
      .catch(error => {
        cb(false);
        dispatch({
          type: settingsTypes.COUNT_ERROR
        });
        dispatch({
          type: types.ERROR_PUT_FEEDBACK,
          error
        });
      });
  };
};

const getLiabilities = dealUuid => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_GET_LIABILITIES
    });

    return api.budget
      .getLiabilities(dealUuid)
      .then(res => {
        dispatch({
          type: types.SUCCESS_GET_LIABILITIES,
          liabilities: res
        });
      })
      .catch(error => {
        dispatch({
          type: types.ERROR_GET_LIABILITIES,
          error
        });
      });
  };
};

const confirmBudgetAccounting = (dealUuid, formdata, cb) => {
  return (dispatch, _getState, api) => {
    dispatch({
      type: types.REQUEST_CONFIRM_BUDGET_ACCOUNTING,
      formdata
    });

    return api.budget
      .confirmBudgetAccounting(dealUuid, formdata)
      .then(() => {
        dispatch({
          type: types.SUCCESS_CONFIRM_BUDGET_ACCOUNTING
        });
        cb(true);
      })
      .catch(error => {
        cb(true);
        if (error.checkout) {
          dispatch({
            type: types.GATEWAY_CHECKOUT,
            error: error.error.context.data
          });
          dispatch(push('/checkout/gateway'));
        }
        dispatch({
          type: types.ERROR_CONFIRM_BUDGET_ACCOUNTING,
          error
        });
      });
  };
};

export default {
  getAccounting,
  putFeedback,
  getLiabilities,
  confirmBudgetAccounting
};
