const routes = {
  BUDGET_DEBTOR_PROPOSAL: {
    previous: 'BUDGET_DEBTOR_PROPOSAL',
    step: 2,
    path: '/budget/debtor/proposal'
  },
  BUDGET_DEBTOR_CREATE: {
    previous: 'BUDGET_DEBTOR_PROPOSAL',
    step: 2,
    path: '/budget/debtor/create'
  },
  BUDGET_BANK_START: {
    previous: 'BUDGET_DEBTOR_CREATE',
    step: 2,
    path: '/budget/bank/start'
  },
  BUDGET_BANK_SCENARIOS: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: '/budget/bank/scenario'
  },
  BUDGET_BANK_SCENARIOS_PUBLIC_URL: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: `${process.env.PUBLIC_URL}/budget/bank/scenario`
  },
  BUDGET_BANK_SYNC: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: '/budget/bank/sync'
  },
  BUDGET_BANK_SYNC_PUBLIC_URL: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: `${process.env.PUBLIC_URL}/budget/bank/sync`
  },
  BUDGET_BANK_SEARCH: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: '/budget/bank/search'
  },
  BUDGET_BANK_IBAN: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/bank/iban'
  },
  BUDGET_BANK_LOGIN: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/bank/login'
  },
  BUDGET_BANK_ACCESS: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/bank/access'
  },
  BUDGET_BANK_ACCESS_LOGIN_SYNC: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/bank/access-login-sync'
  },
  BUDGET_BANK_ACCESS_SYNC: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/bank/access-sync'
  },
  BUDGET_DEBTOR_ACCOUNTS: {
    previous: 'BUDGET_BANK_SEARCH',
    step: 2,
    path: '/budget/debtor/accounts'
  },
  BUDGET_DEBTOR_INCOME: {
    previous: 'BUDGET_BANK_START',
    step: 2,
    path: '/budget/debtor/income'
  },
  BUDGET_DEBTOR_MORE_BANKS: {
    previous: 'BUDGET_DEBTOR_INCOME',
    step: 2,
    path: '/budget/debtor/more-banks'
  },
  BUDGET_ACCOUNTING_PERSONS: {
    previous: 'BUDGET_DEBTOR_MORE_BANKS',
    step: 2,
    path: '/budget/accounting/feedback/persons'
  },
  BUDGET_ACCOUNTING_PERIODIC_PAYMENTS: {
    previous: 'BUDGET_ACCOUNTING_PERSONS',
    step: 2,
    path: '/budget/accounting/feedback/periodic-payments'
  },
  BUDGET_ACCOUNTING_RENTS: {
    previous: 'BUDGET_ACCOUNTING_PERIODIC_PAYMENTS',
    step: 2,
    path: '/budget/accounting/feedback/rents'
  },
  BUDGET_ACCOUNTING_LOANS: {
    previous: 'BUDGET_ACCOUNTING_RENTS',
    step: 2,
    path: '/budget/accounting/feedback/loans'
  },
  BUDGET_ACCOUNTING_SAVINGS: {
    previous: 'BUDGET_ACCOUNTING_LOANS',
    step: 2,
    path: '/budget/accounting/feedback/savings'
  },
  BUDGET_ACCOUNTING_INSURANCES: {
    previous: 'BUDGET_ACCOUNTING_SAVINGS',
    step: 2,
    path: '/budget/accounting/feedback/insurances'
  },
  BUDGET_ACCOUNTING_HIGH_EXPENSES: {
    previous: 'BUDGET_ACCOUNTING_INSURANCES',
    step: 2,
    path: '/budget/accounting/feedback/high-expenses'
  },
  BUDGET_ACCOUNTING_VEHICLES: {
    previous: 'BUDGET_ACCOUNTING_HIGH_EXPENSES',
    step: 2,
    path: '/budget/accounting/feedback/vehicles'
  },
  BUDGET_ACCOUNTING: {
    previous: 'BUDGET_ACCOUNTING_VEHICLES',
    step: 2,
    path: '/budget/accounting'
  },
  CHECKOUT_PROCESSING: {
    previous: 'CHECKOUT_PROCESSING',
    step: 4,
    path: '/checkout/processing'
  },
  CHECKOUT_SUCCESS: {
    previous: 'CHECKOUT_SUCCESS',
    step: 5,
    path: '/checkout/success'
  },
  CHECKOUT_GATEWAY: {
    previous: 'CHECKOUT_GATEWAY',
    step: -1,
    path: '/checkout/gateway'
  },
  CHECKOUT_CONFIRMATION: {
    previous: 'CHECKOUT_CONFIRMATION',
    step: -1,
    path: '/checkout/confirmation'
  },
  CHECKOUT_ERROR: {
    previous: 'CHECKOUT_ERROR',
    step: -1,
    path: '/checkout/error'
  },
  CHECKOUT_CANCEL: {
    previous: 'CHECKOUT_CANCEL',
    step: -1,
    path: '/checkout/cancel'
  }
};

export default routes;
