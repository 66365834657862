import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    Loader: {
      width: '100%',
      background: theme.color.background.light,
      zIndex: 9999,
      display: 'flex',
      flexDirection: 'column'
    },
    transparent: {
      background: 'transparent'
    },
    Spinner: {
      margin: '0 auto',
      alignItems: 'center',
      justifyContent: 'center'
    },
    message: {
      width: '100%',
      margin: '0 auto',
      textAlign: 'center',
      marginTop: '-60px',
      color: theme.color.secondary
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    SpinnerElement: {
      borderWidth: '8px',
      borderStyle: 'solid',
      borderColor: `${theme.color.secondary} ${theme.color.secondary} ${theme.color.secondary} ${theme.color.secondaryOpacity}`,
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      animation: '$spin 1.5s infinite linear',
      position: 'relative',
      margin: '6em auto',
      '&:before': {
        top: '8px',
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: theme.color.secondary,
        position: 'absolute',
        left: '8px'
      },
      '&:after': {
        bottom: '8px',
        content: '""',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        background: theme.color.secondary,
        position: 'absolute',
        left: '8px'
      }
    }
  });

export default styles;
