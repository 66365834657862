import React from 'react';
import { makeStyles, IconButton, Popover } from '@material-ui/core';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import InfoIcon from '@material-ui/icons/InfoSharp';
import classNames from 'classnames';
import styles from './headline.styles';

const useStyles = makeStyles(styles);

interface IHeadline {
  text?: string;
  subtext?: string;
  className?: string;
  inversed?: boolean;
  translate?: boolean;
  textParams?: any;
  subtextParams?: any;
  hasHint?: boolean;
  hintText?: string;
  isHintModal?: boolean;
  hintClick?: () => void;
}
const Headline: React.FC<IHeadline> = props => {
  const {
    text,
    subtext,
    translate,
    className,
    inversed,
    textParams,
    subtextParams,
    hasHint,
    hintText,
    isHintModal,
    hintClick
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let combinedHeadlineClasses = classNames(className, classes.Headline);
  let combinedSubtextClasses = classNames(className, classes.Subtext);
  if (inversed) {
    combinedHeadlineClasses = classNames(className, classes.Headline, classes.inversed);
    combinedSubtextClasses = classNames(className, classes.Subtext, classes.inversed);
  }
  return (
    <>
      <span className={combinedHeadlineClasses}>
        {translate ? <FormattedMessage id={text} values={textParams} /> : text}
        {hasHint && (
          <>
            <IconButton aria-label="Close" size="small" className={classes.hintButton} onClick={handleClick}>
              <InfoIcon fontSize="inherit" />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <div className={classes.hintPopover}>
                <FormattedMessage id={hintText}>
                  {txt => <span className={classes.hintText}>{txt}</span>}
                </FormattedMessage>
              </div>
            </Popover>
          </>
        )}
        {isHintModal && (
          <>
            <IconButton aria-label="Close" size="small" className={classes.hintButton} onClick={hintClick}>
              <InfoIcon fontSize="inherit" />
            </IconButton>
          </>
        )}
      </span>
      {subtext && (
        <span className={combinedSubtextClasses}>
          {translate ? <FormattedHTMLMessage id={subtext} values={subtextParams} /> : subtext}
        </span>
      )}
    </>
  );
};

Headline.defaultProps = {
  translate: true
};
export default Headline;
