export default {
  REQUEST_SET_LOAN_ESTIMATOR: 'LOAN_REQUEST_SET_LOAN_ESTIMATOR',
  SUCCESS_SET_LOAN_ESTIMATOR: 'LOAN_SUCCESS_SET_LOAN_ESTIMATOR',
  ERROR_SET_LOAN_ESTIMATOR: 'LOAN_ERROR_SET_LOAN_ESTIMATOR',
  CALCULATE_LOAN: 'LOAN_CALCULATE_LOAN',
  REQUEST_GET_ANNUITY: 'LOAN_REQUEST_GET_ANNUITY',
  SUCCESS_GET_ANNUITY: 'LOAN_SUCCESS_GET_ANNUITY',
  ERROR_GET_ANNUITY: 'LOAN_ERROR_GET_ANNUITY',
  REQUEST_GET_AMORTIZATION: 'LOAN_REQUEST_GET_AMORTIZATION',
  SUCCESS_GET_AMORTIZATION: 'LOAN_SUCCESS_GET_AMORTIZATION',
  ERROR_GET_AMORTIZATION: 'LOAN_ERROR_GET_AMORTIZATION',
  REQUEST_SUBMIT_AMORTIZATION: 'LOAN_REQUEST_SUBMIT_AMORTIZATION',
  SUCCESS_SUBMIT_AMORTIZATION: 'LOAN_SUCCESS_SUBMIT_AMORTIZATION',
  ERROR_SUBMIT_AMORTIZATION: 'LOAN_ERROR_SUBMIT_AMORTIZATION',
  CHANGE_FIXEDINTEREST: 'LOAN_CHANGE_FIXEDINTEREST',
  CONFIRM_EQUITY: 'LOAN_CONFIRM_EQUITY',
  RESET_LOAN_ESTIMATOR: 'LOAN_RESET_LOAN_ESTIMATOR',
  RESET_CREDIT_MODEL: 'LOAN_RESET_CREDIT_MODEL'
};
