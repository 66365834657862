import React from 'react';
import { Grid, Slide } from '@material-ui/core';
import Headline from '../../components/headline';

const Page404: React.FC = () => (
  <Slide direction="left" in mountOnEnter unmountOnExit>
    <Grid container>
      <Grid item xs={12}>
        <Headline text="app.error.404.title" />
      </Grid>
    </Grid>
  </Slide>
);

export default Page404;
