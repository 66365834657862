import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  DialogActions,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './modal.styles';

const useStyles = makeStyles(styles);

interface IModal {
  open: boolean;
  handleClose?: () => void;
  title?: ReactElement | string;
  content?: ReactElement | string;
  actions?: ReactElement | string;
  isClosable?: boolean;
  classesTitle?: string;
  classesContent?: string;
  classesActions?: string;
  isErrorModal?: boolean;
}
const Modal: React.FC<IModal> = props => {
  const {
    open,
    handleClose,
    title,
    content,
    actions,
    isClosable,
    classesTitle,
    classesContent,
    classesActions,
    isErrorModal
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title" className={classesTitle}>
        {title}
        {isClosable && !isErrorModal && (
          <IconButton aria-label="close" className={classes.modalCloseButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classesContent}>{content}</DialogContent>
      {actions && <DialogActions className={classesActions}>{actions}</DialogActions>}
    </Dialog>
  );
};

Modal.defaultProps = {
  content: <></>,
  handleClose: () => {},
  actions: <></>,
  title: <></>,
  isClosable: true,
  classesTitle: '',
  classesActions: '',
  classesContent: '',
  isErrorModal: false
};

export default Modal;
