import React, { Suspense } from 'react';
import { useLocation, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Loader from '../../components/loader';
import Scene404 from '../../views/static/Page404';
import routes from '../../components/global/routes';

const BudgetDebtorProposal = React.lazy(() => import('../../views/budget/debtor/proposal'));
const BudgetDebtorCreate = React.lazy(() => import('../../views/budget/debtor/create'));

const BudgetBankStart = React.lazy(() => import('../../views/budget/bank/start'));
const BudgetBankScenarios = React.lazy(() => import('../../views/budget/bank/scenarios'));
const BudgetBankSync = React.lazy(() => import('../../views/budget/bank/sync'));
const BudgetDebtorIncome = React.lazy(() => import('../../views/budget/debtor/income'));
const BudgetDebtorMoreBanks = React.lazy(() => import('../../views/budget/debtor/more-banks'));
const BudgetAccountingPersons = React.lazy(() => import('../../views/budget/accounting/persons'));
const BudgetAccountingPeriodicPayments = React.lazy(() => import('../../views/budget/accounting/periodic-payments'));
const BudgetAccountingRents = React.lazy(() => import('../../views/budget/accounting/rents'));
const BudgetAccountingLoans = React.lazy(() => import('../../views/budget/accounting/loans'));
const BudgetAccountingSavings = React.lazy(() => import('../../views/budget/accounting/savings'));
const BudgetAccountingVehicles = React.lazy(() => import('../../views/budget/accounting/vehicles'));
const BudgetAccounting = React.lazy(() => import('../../views/budget/accounting'));
const BudgetAccountingInsurances = React.lazy(() => import('../../views/budget/accounting/insurances'));
const BudgetAccountingHighExpenses = React.lazy(() => import('../../views/budget/accounting/high-expenses'));

const CheckoutProcessing = React.lazy(() => import('../../views/checkout/processing'));
const CheckoutSuccess = React.lazy(() => import('../../views/checkout/success'));
const CheckoutGateway = React.lazy(() => import('../../views/checkout/gateway'));
const CheckoutConfirmation = React.lazy(() => import('../../views/checkout/confirmation'));
const CheckoutError = React.lazy(() => import('../../views/checkout/error-checkout'));
const CheckoutCancel = React.lazy(() => import('../../views/checkout/cancel'));

interface IRouter {
  location: any;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Router: React.FC<IRouter> = props => {
  const { location } = props;
  const query = useQuery();
  const reference = query.get('antragsNr');
  const beraterMail = query.get('email');
  const interhypRedirectUrl = query.get('redirectURL');
  return (
    <Suspense fallback={<Loader isLoading />}>
      <LastLocationProvider>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect
                to={`/budget/debtor/proposal?antragsNr=${reference}&email=${beraterMail}&redirectURL=${interhypRedirectUrl}`}
              />
            )}
          />
          <Route key={location.key} path={routes.BUDGET_DEBTOR_PROPOSAL.path} component={BudgetDebtorProposal} />
          <Route key={location.key} path={routes.BUDGET_DEBTOR_CREATE.path} component={BudgetDebtorCreate} />
          <Route key={location.key} path={routes.BUDGET_BANK_START.path} component={BudgetBankStart} />
          <Route key={location.key} path={routes.BUDGET_BANK_SCENARIOS.path} component={BudgetBankScenarios} />
          <Route
            key={location.key}
            path={routes.BUDGET_BANK_SCENARIOS_PUBLIC_URL.path}
            component={BudgetBankScenarios}
          />
          <Route key={location.key} path={routes.BUDGET_BANK_SYNC.path} component={BudgetBankSync} />
          <Route key={location.key} path={routes.BUDGET_BANK_SYNC_PUBLIC_URL.path} component={BudgetBankSync} />
          <Route key={location.key} path={routes.BUDGET_DEBTOR_INCOME.path} component={BudgetDebtorIncome} />
          <Route key={location.key} path={routes.BUDGET_DEBTOR_MORE_BANKS.path} component={BudgetDebtorMoreBanks} />
          <Route key={location.key} path={routes.BUDGET_ACCOUNTING.path} component={BudgetAccounting} exact />
          <Route key={location.key} path={routes.BUDGET_ACCOUNTING_PERSONS.path} component={BudgetAccountingPersons} />
          <Route
            key={location.key}
            path={routes.BUDGET_ACCOUNTING_PERIODIC_PAYMENTS.path}
            component={BudgetAccountingPeriodicPayments}
          />
          <Route key={location.key} path={routes.BUDGET_ACCOUNTING_RENTS.path} component={BudgetAccountingRents} />
          <Route key={location.key} path={routes.BUDGET_ACCOUNTING_LOANS.path} component={BudgetAccountingLoans} />
          <Route key={location.key} path={routes.BUDGET_ACCOUNTING_SAVINGS.path} component={BudgetAccountingSavings} />
          <Route
            key={location.key}
            path={routes.BUDGET_ACCOUNTING_VEHICLES.path}
            component={BudgetAccountingVehicles}
          />
          <Route
            key={location.key}
            path={routes.BUDGET_ACCOUNTING_INSURANCES.path}
            component={BudgetAccountingInsurances}
          />
          <Route
            key={location.key}
            path={routes.BUDGET_ACCOUNTING_HIGH_EXPENSES.path}
            component={BudgetAccountingHighExpenses}
          />

          <Route key={location.key} path={routes.CHECKOUT_PROCESSING.path} component={CheckoutProcessing} />
          <Route key={location.key} path={routes.CHECKOUT_SUCCESS.path} component={CheckoutSuccess} />
          <Route key={location.key} path={routes.CHECKOUT_GATEWAY.path} component={CheckoutGateway} />
          <Route key={location.key} path={routes.CHECKOUT_CONFIRMATION.path} component={CheckoutConfirmation} />
          <Route key={location.key} path={routes.CHECKOUT_ERROR.path} component={CheckoutError} />
          <Route key={location.key} path={routes.CHECKOUT_CANCEL.path} component={CheckoutCancel} />

          <Route component={Scene404} />
        </Switch>
      </LastLocationProvider>
    </Suspense>
  );
};

export default withRouter(Router);
