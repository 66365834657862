import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    actionButton: {
      background: theme.color.secondary,
      minWidth: '160px',
      margin: '10px',
      color: theme.color.font.light,
      '&:hover': {
        background: theme.color.secondaryVariant
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '120px'
      }
    },
    sm: {
      minWidth: '60px',
      fontSize: '12px',
      textTransform: 'none',
      minHeight: '34px',
      maxHeight: '34px',
      maxWidth: '120px',
      margin: '0px'
    },
    inversed: {
      background: theme.color.background.light,
      color: theme.color.main,
      '&:hover': {
        background: theme.color.background.grey
      }
    },
    fullWidth: {
      maxWidth: '100%',
      width: '100%',
      marginLeft: '0px',
      marginRight: '0px'
    }
  });

export default styles;
