import { createMuiTheme } from '@material-ui/core/styles';
import { blue, deepOrange, red } from '@material-ui/core/colors';

export default createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1400,
      xl: 1920
    }
  },
  palette: {
    primary: {
      light: blue[100],
      main: 'rgb(0,68,97)',
      dark: blue[700]
    },
    secondary: {
      light: deepOrange[300],
      main: 'rgb(233,141,0)',
      dark: deepOrange[700]
    },
    tertiary: {
      light: blue[100],
      main: '#3385C2',
      dark: blue[700]
    },
    error: {
      main: red[500]
    }
  },
  color: {
    main: 'rgb(0,68,97)',
    mhbblue: 'rgb(0,68,97)',
    navbar: 'rgb(216,216,216)',
    mainVariant: '#3385C2',
    secondary: 'rgb(233,141,0)',
    secondaryOpacity: 'rgba(240,78,0, 0.2)',
    grey: '#7f7f7f',
    font: {
      light: '#ffffff',
      dark: '#333333'
    },
    background: {
      light: '#ffffff',
      lightGrey: '#EAEFF4',
      grey: '#c0c0c0',
      dark: '#333333'
    },
    status: {
      error: '#D50000'
    }
  },
  responseSpacing: {
    unit: 2,
    xs: 1,
    sm: 1,
    md: 2,
    lg: 5,
    xl: 5
  },
  padding: {
    boxes: 20
  },
  typography: {
    fontFamily: ['BuenosAires'],
    useNextVariants: {
      useNextVariants: true
    }
  },
  fontSize: {
    boxes: {
      headline: {
        label: '16px',
        value: '24px'
      },
      label: '16px',
      value: '20px'
    },
    stepper: {
      headline: {
        lg: '32px',
        xs: '18px'
      },
      text: {
        lg: '18px',
        xs: '12px'
      },
      link: '16px'
    },
    headline: {
      lg: '36px',
      md: '26px',
      xs: '20px'
    },
    subHeadline: {
      lg: '18px',
      xs: '14px'
    },
    label: {
      lg: '18px',
      xs: '16px',
      xxs: '12px'
    },
    menuItem: {
      lg: '16px',
      md: '12px'
    }
  },
  boxShadow: '0px 3px 14px #D6D6D6'
});
