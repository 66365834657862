// export default (initialState, reducerMap) => (state = initialState, action) => {
//   const reducer = reducerMap[action.type];
//   return reducer ? reducer(state, action) : state;
// };

export default (getInitialState = () => {}, handlers = []) => {
  const Reducer = (state = getInitialState(), action = {}) => {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };

  Reducer.handlers = handlers;

  return Reducer;
};
