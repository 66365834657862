import { createStyles } from '@material-ui/core';
import { ICustomTheme } from './typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      height: 140,
      width: 100
    },
    control: {
      padding: theme.responseSpacing.unit * 2
    },
    Viewport: {
      flex: '1',
      height: '100%',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginTop: '20px !important',
      marginBottom: '0px !important',
      overflowX: 'hidden',
      textAlign: 'center',
      position: 'relative',
      alignContent: 'start',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        height: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px !important',
        paddingTop: '0px !important',
        marginTop: '20px !important',
        height: '100%',
        boxSizing: 'border-box'
      }
    },
    progressPrimary: {
      backgroundColor: theme.color.secondary
    },
    colorPrimary: {
      backgroundColor: theme.color.secondaryOpacity,
      height: '6px',
      borderRadius: '0px'
    },
    tenantLogo: {
      height: '48px',
      position: 'relative',
      float: 'right',
      marginRight: '14px'
    },
    LogoBar: {
      height: '60px',
      width: '100%'
    }
  });

export default styles;
