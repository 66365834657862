import React, { ReactElement } from 'react';
import {
  AppBar,
  Snackbar,
  Menu,
  MenuItem,
  makeStyles,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Tooltip
} from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import './navbar.css';
import Logo from '../../static/images/logo.png';
import styles from './navbar.styles';
import Modal from './navbar.modal';
// import logoVR from '../../static/images/logo_vr.png';
// import logoSparda from '../../static/images/sparda.jpg';
import { ITenantStore } from '../../typings/interfaces';

const useStyles = makeStyles(styles);

interface WindowEnv extends Window {
  env: {
    PROFILE: string;
  };
}
interface WindowVersion extends Window {
  version: string;
}

const windowUnknown = window as unknown;
const windowEnv = windowUnknown as WindowEnv;
const windowVersion = windowUnknown as WindowVersion;

interface INavbar {
  tenant: ITenantStore;
  uuid: string;
}
const Navbar: React.FC<INavbar> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    tenant: { baseData },
    uuid
  } = props;
  const [deleteStoreSnackbarOpen, openDeleteStoreSnackbar] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [navbarModalOpen, setOpen] = React.useState(false);
  const [navbarModalContent, setContent] = React.useState<ReactElement | string | Element>();
  const [navbarModalHeadline, setHeadline] = React.useState<ReactElement | string | Element>();

  // let spardaName = '';
  // if (baseData && baseData.name) {
  //   spardaName = baseData.name.toLowerCase();
  // }
  // const isSparda = spardaName.includes('sparda');

  const versionString = `Version ${windowVersion.version}`;

  const handleModalOpen = (type: string) => {
    let headline;
    let content;

    switch (type) {
      case 'imprint':
        headline = <FormattedMessage id="app.imprint.title" />;

        content = (
          <>
            <p>
              <strong>{versionString}</strong>
            </p>
            <a href={baseData.imprint} target="_blank" rel="noopener noreferrer">
              {baseData.imprint}
            </a>
          </>
        );
        break;

      case 'termsOfUse':
        headline = <></>;
        content = <Markdown>{baseData.termsOfUse}</Markdown>;
        break;

      case 'dataPrivacyDocument':
        headline = <></>;
        content = <Markdown>{baseData.dataPrivacyDocument}</Markdown>;
        break;

      case 'contact':
        headline = <FormattedMessage id="app.contact.title" />;
        content = (
          <>
            <span className={classes.tenantHeader}>{baseData.name}</span>
            <span className={classes.tenantAdress}>
              {`${baseData.address.street} ${baseData.address.streetNumber} `}
            </span>
            <span className={classes.tenantAdress}>{`${baseData.address.postalCode} ${baseData.address.city} `}</span>
            <span className={classes.tenantAdress}>{baseData.address.country}</span>
          </>
        );
        break;

      default:
        break;
    }

    setContent(content);
    setHeadline(headline);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const deleteStore = () => {
    sessionStorage.clear();
    localStorage.clear();
    openDeleteStoreSnackbar(true);
  };

  const closeSnackbar = () => {
    openDeleteStoreSnackbar(false);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{
        paper: classes.mobileMenu
      }}
    >
      <MenuItem onClick={() => handleModalOpen('contact')}>
        <FormattedMessage id="app.contact.title" />
      </MenuItem>
      <MenuItem onClick={() => handleModalOpen('imprint')}>
        <FormattedMessage id="app.imprint.title" />
      </MenuItem>
      <MenuItem onClick={() => handleModalOpen('dataPrivacyDocument')}>
        <FormattedMessage id="app.privacy.title.short" />
      </MenuItem>
      <MenuItem onClick={() => handleModalOpen('termsOfUse')}>
        <FormattedMessage id="app.termsOfUseUrl.title" />
      </MenuItem>
    </Menu>
  );

  const interhypRedirectUrl = sessionStorage.getItem('interhypRedirectUrl');

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.Navbar}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={deleteStoreSnackbarOpen}
          autoHideDuration={3000}
          onClose={closeSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">Browserstorage wurde gelöscht</span>}
        />
        <IconButton className={classes.burgerButton} aria-label="mobile-menu" onClick={handleMobileMenuOpen}>
          {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        {interhypRedirectUrl && interhypRedirectUrl !== 'null' && interhypRedirectUrl !== '' && (
          <div className={classes.Cancel}>
            <Tooltip title="Ich möchte die Strecke abbrechen und keinen weiteren Versuch starten">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  window.location.href = `${interhypRedirectUrl}?status=CANCEL`;
                }}
              >
                Abbrechen
              </Button>
            </Tooltip>
          </div>
        )}
        <img
          src={Logo}
          alt="MünchenerHyp"
          className={classes.Logo}
          onClick={e => {
            e.preventDefault();
            if (windowEnv.env.PROFILE === 'DEVELOPMENT' || windowEnv.env.PROFILE === 'TEST') {
              deleteStore();
              window.location.href = '/';
            }
          }}
        />

        {renderMenu}
        <Modal
          open={navbarModalOpen}
          content={navbarModalContent}
          headline={navbarModalHeadline}
          onClose={handleModalClose}
          fullScreen={isMobile}
        />
      </AppBar>
      {(windowEnv.env.PROFILE === 'DEVELOPMENT' || windowEnv.env.PROFILE === 'TEST') && (
        <span className={classes.navbarUuid}>{uuid}</span>
      )}
    </div>
  );
};

export default Navbar;
