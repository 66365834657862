import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { intlReducer } from 'react-intl-redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import * as History from 'history';
import thunk from 'redux-thunk';
import reducers, { blacklistApiStatus } from './rootReducer';
import api from '../api';
import de from '../static/translations/de.json';
import en from '../static/translations/en.json';

export const history = History.createBrowserHistory();

const availableLangs = {
  de,
  en
};
const browserLang = 'de';
const initialState = {
  intl: {
    locale: browserLang,
    messages: availableLangs[browserLang]
  }
};
const enhancers = [];

let storage = sessionStorage;

if (process.env.REACT_APP_USE_LOCAL_STORAGE) {
  storage = localStorage;
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'router', 'intl'],
  transforms: blacklistApiStatus,
  stateReconciler: autoMergeLevel2
};

const middleware = [thunk.withExtraArgument(api), routerMiddleware(history)];

const rootReducer = combineReducers({
  router: connectRouter(history),
  intl: intlReducer,
  ...reducers
});

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const store = createStore(persistReducer(persistConfig, rootReducer), initialState, composedEnhancers);
export const persistor = persistStore(store);
