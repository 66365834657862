export default function tenant(apiWrapper) {
  const getTenant = () => {
    return apiWrapper.fetchUnauthorized({
      endpoint: '/tenant',
      method: 'GET'
    });
  };

  const getTenants = zipCode => {
    return apiWrapper.fetchUnauthorized({
      endpoint: `/tenant/tenants?zipCode=${zipCode}`,
      method: 'GET'
    });
  };

  return {
    getTenant,
    getTenants
  };
}
