import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import styles from './backward-button.styles';

const useStyles = makeStyles(styles);

interface IBackwardButton {
  onClick: () => void;
}
const BackwardButton: React.FC<IBackwardButton> = props => {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Fab
      variant="extended"
      aria-label="Add"
      id="backwardButton"
      name="backwardButton"
      type="button"
      className={classes.backwardButton}
      onClick={onClick}
    >
      <KeyboardArrowLeft className={classes.backwardButtonIcon} />
    </Fab>
  );
};

export default BackwardButton;
