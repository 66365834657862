import types from './types';
import createReducer from '../../utils/createReducer';

const getInitialState = () => {
  return {
    forwardButtonDisabled: false,
    progress: 0,
    step: 1,
    welcomeModal: {
      show: true,
      processData: false,
      acceptedPrivacy: false,
      acceptedTerms: false
    },
    errorCounter: 0,
    showErrorModal: false,
    confirmed: {
      equity: false,
      bankSearch: false,
      budgetFeedbackStart: false
    },
    error: {
      getToken: false,
      meta: {}
    },
    snackbar: {
      error: {
        show: false,
        message: ''
      }
    }
  };
};

const acceptTerms = state => {
  return {
    ...state,
    welcomeModal: {
      ...state.welcomeModal,
      acceptedTerms: !state.welcomeModal.acceptedTerms
    }
  };
};

const processData = state => {
  return {
    ...state,
    welcomeModal: {
      ...state.welcomeModal,
      processData: !state.welcomeModal.processData
    }
  };
};

const acceptPrivacy = state => {
  return {
    ...state,
    welcomeModal: {
      ...state.welcomeModal,
      acceptedPrivacy: !state.welcomeModal.acceptedPrivacy
    }
  };
};

const closeWelcomeModal = state => {
  return {
    ...state,
    welcomeModal: {
      ...state.welcomeModal,
      show: false
    }
  };
};

const showSnackbarError = (state, action) => {
  const { error } = action;
  return {
    ...state,
    snackbar: {
      error: {
        show: true,
        message: error.id
      }
    }
  };
};

const hideSnackbarError = state => {
  return {
    ...state,
    snackbar: {
      error: {
        show: false,
        message: ''
      }
    }
  };
};

const setProgress = (state, action) => {
  const { progress } = action;
  return {
    ...state,
    progress
  };
};

const setStep = (state, action) => {
  const { step } = action;
  return {
    ...state,
    step
  };
};

const setConfirmed = (state, action) => {
  const { identifier } = action;
  return {
    ...state,
    confirmed: {
      ...state.confirmed,
      [identifier]: true
    }
  };
};

const closeErrorModal = state => {
  return {
    ...state,
    showErrorModal: false
  };
};

const countError = state => {
  return {
    ...state,
    errorCounter: state.errorCounter + 1,
    showErrorModal: true
  };
};

const setError = (state, action) => {
  const {
    error: { name, meta }
  } = action;

  return {
    ...state,
    error: {
      ...state.error,
      [name]: true,
      meta
    }
  };
};

const unsetError = (state, action) => {
  const {
    error: { name }
  } = action;
  return {
    ...state,
    error: {
      ...state.error,
      [name]: false,
      meta: {}
    }
  };
};

const handlers = {
  [types.ACCEPT_TERMS]: acceptTerms,
  [types.ACCEPT_PRIVACY]: acceptPrivacy,
  [types.PROCESS_DATA]: processData,
  [types.CLOSE_WELCOME_MODAL]: closeWelcomeModal,
  [types.SNACKBAR_ERROR_SHOW]: showSnackbarError,
  [types.SNACKBAR_ERROR_HIDE]: hideSnackbarError,
  [types.SET_PROGRESS]: setProgress,
  [types.SET_STEP]: setStep,
  [types.SET_CONFIRMED]: setConfirmed,
  [types.CLOSE_ERROR_MODAL]: closeErrorModal,
  [types.COUNT_ERROR]: countError,
  [types.SET_ERROR]: setError,
  [types.UNSET_ERROR]: unsetError
};
export default createReducer(getInitialState, handlers);
