import tenantApi from './tenant';
import apiWrapper from './wrapper';
import dealApi from './deal';
import bankApi from './bank';
import debtorApi from './debtor';
import budgetApi from './budget';
import loanApi from './loan';

export default {
  tenant: tenantApi(apiWrapper),
  deal: dealApi(apiWrapper),
  bank: bankApi(apiWrapper),
  debtor: debtorApi(apiWrapper),
  budget: budgetApi(apiWrapper),
  loan: loanApi(apiWrapper)
};
