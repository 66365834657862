import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const getInitialState = () => {
  return {
    baseData: {
      name: null,
      consent: null,
      address: {
        street: null,
        zipCode: null,
        city: null,
        country: null
      },
      phone: null,
      email: null,
      logo: null,
      contacts: [],
      imprint: null,
      welcomeText: null,
      privacy: null,
      dataPrivacyDocument: null,
      preContractualInformation: null,
      termsOfUseUrl: null,
      termsAndConditionsUrl: null
    },
    creditModel: {
      maxFinancingAmount: null,
      minRepayment: null,
      maxCreditPeriod: null,
      maxCashflowRate: null,
      maxLongtermCashflowRate: null,
      generateContractDocuments: 'FINANCING_CERTIFICATE'
    },
    tenants: [],
    apiStatus: {
      getTenant: {},
      getTenants: {}
    }
  };
};

const requestGetTenant = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTenant: request()
    }
  };
};

const successGetTenant = (state, action) => {
  const { tenant } = action;
  return {
    ...state,
    ...tenant,
    apiStatus: {
      ...state.apiStatus,
      getTenant: result()
    }
  };
};

const errorGetTenant = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTenant: result(error)
    }
  };
};

const requestGetTenants = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTenants: request()
    }
  };
};

const successGetTenants = (state, action) => {
  const { tenants } = action;
  return {
    ...state,
    tenants,
    apiStatus: {
      ...state.apiStatus,
      getTenants: result()
    }
  };
};

const errorGetTenants = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getTenants: result(error)
    }
  };
};

const handlers = {
  [types.REQUEST_GET_TENANT]: requestGetTenant,
  [types.SUCCESS_GET_TENANT]: successGetTenant,
  [types.ERROR_GET_TENANT]: errorGetTenant,
  [types.REQUEST_GET_TENANTS]: requestGetTenants,
  [types.SUCCESS_GET_TENANTS]: successGetTenants,
  [types.ERROR_GET_TENANTS]: errorGetTenants
};
export default createReducer(getInitialState, handlers);
