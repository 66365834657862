export default function loan(apiHelper) {
  const estimateLoan = (dealUuid, formdata) => {
    return apiHelper.fetchUnauthorized({
      endpoint: '/loan/estimator',
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      },
      payload: formdata
    });
  };

  const getAnnuity = dealUuid => {
    return apiHelper.fetchUnauthorized({
      endpoint: '/loan/annuity',
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const getAmortization = (dealUuid, fixedInterest, monthlyPayment) => {
    return apiHelper.fetchUnauthorized({
      endpoint: `/loan/amortization?fixedInterestPeriod=${fixedInterest}&monthlyPayment=${monthlyPayment}`,
      method: 'GET',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  const submitAmortization = (dealUuid, fixedInterest, monthlyPayment) => {
    return apiHelper.fetchUnauthorized({
      endpoint: `/loan/amortization?fixedInterestPeriod=${fixedInterest}&monthlyPayment=${monthlyPayment}`,
      method: 'POST',
      headers: {
        'X-MHB-DEAL-UUID': dealUuid
      }
    });
  };

  return {
    estimateLoan,
    getAnnuity,
    getAmortization,
    submitAmortization
  };
}
