import types from './types';
import createReducer from '../../utils/createReducer';
import { request, result } from '../lib/common';

const getInitialState = () => {
  return {
    agent: null,
    equity: null,
    loanAmount: null,
    modernization: null,
    price: null,
    calculationFinished: false,
    equityConfirmed: false,
    totalAddCosts: {
      taxFee: 0,
      taxRate: 0,
      notaryFee: 0,
      notaryCommision: 0,
      agentFee: 0,
      total: 0
    },
    credit: {
      minAnnuities: {},
      maxAnnuities: {},
      rate: 0,
      rate1: 0,
      term6: 0,
      effRate: 0,
      fixedInterest: '',
      specialAmortizationOption: 5,
      annualRepayment: 0,
      interestRate: 0,
      selectedRate: 0,
      minRate: 0,
      maxRate: 0,
      nominalInterestRate: 0,
      effectiveInterestRate: 0,
      firstRepaymentRate: 0,
      loanTerm: 0,
      initialLoad: true,
      totalRepayment: 0,
      rateAmount: 0
    },
    apiStatus: {
      setLoanEstimator: {},
      getAnnuity: { inital: true },
      getAmortization: {}
    }
  };
};

const requestSetLoanEstimator = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      setLoanEstimator: request()
    }
  };
};

const successSetLoanEstimator = (state, action) => {
  const { loan } = action;
  if (loan.totalAddCosts) {
    loan.agent = parseFloat(loan.totalAddCosts.agentCommission * 100).toFixed(2);
    loan.agent = loan.agent.toString().replace('.', ',');
  }
  return {
    ...state,
    calculationFinished: true,
    ...loan,
    apiStatus: {
      ...state.apiStatus,
      setLoanEstimator: result()
    }
  };
};

const errorSetLoanEstimator = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      setLoanEstimator: result(error)
    }
  };
};

const requestGetAnnuity = state => {
  return {
    ...state,
    credit: {
      ...state.credit,
      initialLoad: true
    },
    apiStatus: {
      ...state.apiStatus,
      getAnnuity: request()
    }
  };
};

const successGetAnnuity = (state, action) => {
  const { loan } = action;
  const { credit } = loan;
  const fixedInterest = Object.keys(credit.minAnnuities)[0];
  const minAnnuities = {};
  const maxAnnuities = {};

  Object.keys(credit.minAnnuities).map(key => {
    let minRate = Math.round(credit.minAnnuities[key]);
    while (minRate % 50 !== 0) {
      minRate += 1;
    }
    minAnnuities[key] = minRate;
    return minRate;
  });

  Object.keys(credit.maxAnnuities).map(key => {
    let maxRate = Math.round(credit.maxAnnuities[key]);
    while (maxRate % 50 !== 0) {
      maxRate += 1;
    }
    maxAnnuities[key] = maxRate;
    return maxRate;
  });

  const minRate = minAnnuities[Object.keys(minAnnuities)[0]];
  const maxRate = maxAnnuities[Object.keys(maxAnnuities)[0]];
  let selectedRate = Math.round((minRate + maxRate) / 2);

  while (selectedRate % 50 !== 0) {
    selectedRate -= 1;
  }

  return {
    ...state,
    credit: {
      ...state.credit,
      ...credit,
      maxAnnuities,
      minAnnuities,
      fixedInterest,
      selectedRate,
      minRate,
      maxRate
    },
    apiStatus: {
      ...state.apiStatus,
      getAnnuity: result()
    }
  };
};

const errorGetAnnuity = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAnnuity: result(error)
    }
  };
};

const requestGetAmortization = state => {
  return {
    ...state,
    credit: {
      ...state.credit,
      initialLoad: false
    },
    apiStatus: {
      ...state.apiStatus,
      getAmortization: request()
    }
  };
};

const successGetAmortization = (state, action) => {
  const { loan } = action;
  const { credit } = loan;
  return {
    ...state,
    credit: {
      ...state.credit,
      ...credit
    },
    apiStatus: {
      ...state.apiStatus,
      getAmortization: result()
    }
  };
};

const errorGetAmortization = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAmortization: result(error)
    }
  };
};

const changeFixedInterest = (state, action) => {
  const { value } = action;
  const { credit } = state;
  const minRate = state.credit.minAnnuities[value];
  const maxRate = state.credit.maxAnnuities[value];
  if (minRate > credit.selectedRate) {
    credit.selectedRate = minRate;
  }
  if (maxRate < credit.selectedRate) {
    credit.selectedRate = maxRate;
  }
  return {
    ...state,
    credit: {
      ...state.credit,
      fixedInterest: value,
      minRate,
      maxRate
    }
  };
};

const requestSubmitAmortization = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAmortization: request()
    }
  };
};

const successSubmitAmortization = state => {
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAmortization: result()
    }
  };
};

const errorSubmitAmortization = (state, action) => {
  const { error } = action;
  return {
    ...state,
    apiStatus: {
      ...state.apiStatus,
      getAmortization: result(error)
    }
  };
};

const calculateLoan = (state, action) => {
  const { loan } = action;
  return {
    ...state,
    ...loan
  };
};

const confirmEquity = state => {
  return {
    ...state,
    equityConfirmed: true
  };
};

const resetLoanEstimator = state => {
  return {
    ...state,
    agent: 0,
    equity: 0,
    loanAmount: 0,
    modernization: 0,
    price: 0,
    calculationFinished: false,
    equityConfirmed: false,
    totalAddCosts: {
      taxFee: 0,
      taxRate: 0,
      notaryFee: 0,
      notaryCommision: 0,
      agentFee: 0,
      total: 0
    }
  };
};

const resetCreditModel = state => {
  return {
    ...state,
    credit: {
      minAnnuities: {},
      maxAnnuities: {},
      rate: 0,
      rate1: 0,
      term6: 0,
      effRate: 0,
      fixedInterest: '',
      specialAmortizationOption: 5,
      annualRepayment: 0,
      interestRate: 0,
      selectedRate: 0,
      minRate: 0,
      maxRate: 0,
      nominalInterestRate: 0,
      effectiveInterestRate: 0,
      firstRepaymentRate: 0,
      loanTerm: 0,
      initialLoad: true,
      totalRepayment: 0,
      rateAmount: 0
    }
  };
};

const handlers = {
  [types.REQUEST_SET_LOAN_ESTIMATOR]: requestSetLoanEstimator,
  [types.SUCCESS_SET_LOAN_ESTIMATOR]: successSetLoanEstimator,
  [types.ERROR_SET_LOAN_ESTIMATOR]: errorSetLoanEstimator,
  [types.REQUEST_GET_ANNUITY]: requestGetAnnuity,
  [types.SUCCESS_GET_ANNUITY]: successGetAnnuity,
  [types.ERROR_GET_ANNUITY]: errorGetAnnuity,
  [types.REQUEST_GET_AMORTIZATION]: requestGetAmortization,
  [types.SUCCESS_GET_AMORTIZATION]: successGetAmortization,
  [types.ERROR_GET_AMORTIZATION]: errorGetAmortization,
  [types.CHANGE_FIXEDINTEREST]: changeFixedInterest,
  [types.REQUEST_SUBMIT_AMORTIZATION]: requestSubmitAmortization,
  [types.SUCCESS_SUBMIT_AMORTIZATION]: successSubmitAmortization,
  [types.ERROR_SUBMIT_AMORTIZATION]: errorSubmitAmortization,
  [types.CALCULATE_LOAN]: calculateLoan,
  [types.CONFIRM_EQUITY]: confirmEquity,
  [types.RESET_LOAN_ESTIMATOR]: resetLoanEstimator,
  [types.RESET_CREDIT_MODEL]: resetCreditModel
};
export default createReducer(getInitialState, handlers);
