import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    root: {
      background: theme.color.navbar,
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      margin: '0 auto',
      height: '140px',
      [theme.breakpoints.down('md')]: {
        height: '70px'
      },
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      }
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    Navbar: {
      boxShadow: 'none !important',
      paddingLeft: '100px',
      display: 'contents',
      paddingRight: '100px',
      marginLeft: '0px',
      marginTop: '0px',
      [theme.breakpoints.only('md')]: {
        paddingLeft: '48px',
        paddingRight: '48px'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px'
      }
    },
    navbarUuid: {
      display: 'flex',
      direction: 'rtl',
      fontSize: '12px',
      color: 'white',
      marginRight: '5px',
      marginTop: '-10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '8px'
      },
      position: 'absolute',
      right: '0px',
      bottom: '0px'
    },
    HomeButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      marginTop: '12px',
      [theme.breakpoints.down('md')]: {
        marginTop: '0'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginTop: '0px',
        paddingLeft: '0px',
        height: '100%',
        width: 'auto'
      }
    },
    IconButton: {
      color: theme.color.font.light
    },
    Modal: {
      background: theme.color.background.light,
      color: `${theme.color.font.dark} !important`,
      paddingTop: '12px',
      paddingLeft: '36px',
      paddingBottom: '80px'
    },
    ModalTitleWrapper: {
      background: theme.color.background.light,
      color: `${theme.color.main} !important`,
      paddingTop: '36px',
      paddingLeft: '36px'
    },
    ModalTitle: {
      color: `${theme.color.main} !important`,
      fontSize: '28px',
      fontWeight: 'bold',
      paddingTop: '30px'
    },
    closeButton: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      color: theme.color.main,
      background: `${theme.color.background.light} !important`,
      // fontSize: '14px',
      '&:hover': {
        background: 'transparent',
        color: `${theme.color.main} !important`
      }
    },
    MenuItem: {
      color: `${theme.color.font.light} !important`,
      marginLeft: '5px !important',
      marginRight: '5px !important'
    },
    tenantHeader: {
      display: 'block',
      fontWeight: 'bold',
      fontSize: '18px',
      marginTop: '12px',
      marginBottom: '24px'
    },
    tenantAdress: {
      display: 'block',
      fontSize: '18px',
      marginTop: '8px',
      marginBottom: '8px'
    },
    Cancel: {
      height: '80px',
      marginTop: '40px',
      float: 'right',
      [theme.breakpoints.up('md')]: {
        float: 'right',
        marginRight: '20px'
      },
      [theme.breakpoints.down('md')]: {
        float: 'right',
        height: '30px',
        marginTop: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        float: 'right',
        height: '30px',
        marginTop: '10px'
      }
    },
    Logo: {
      height: '80px',
      marginTop: '30px',
      [theme.breakpoints.up('md')]: {
        float: 'right',
        marginRight: '20px'
      },
      [theme.breakpoints.down('md')]: {
        height: '30px',
        marginTop: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '30px',
        marginTop: '10px'
      }
    },
    tenantLogo: {
      height: '30px',
      marginTop: '30px',
      [theme.breakpoints.down('md')]: {
        height: '30px',
        marginTop: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '30px',
        marginTop: '10px'
      }
    },
    burgerButton: {
      color: theme.color.mhbblue,
      position: 'relative',
      float: 'left',
      left: '28px',
      top: '28px',
      [theme.breakpoints.down('md')]: {
        left: '4px',
        top: '0px'
      },
      '&>span>svg': {
        height: '50px',
        width: '50px',
        [theme.breakpoints.down('md')]: {
          height: '1em',
          width: '1em'
        }
      }
    },
    mobileMenu: {
      background: theme.color.main,
      width: '100%',
      maxWidth: '100%',
      left: '0px !important',
      top: '100px !important',
      borderRadius: '0px',
      color: theme.color.font.light,
      [theme.breakpoints.down('md')]: {
        top: '48px !important'
      }
    }
  });

export default styles;
