import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { settingsActions } from '../../redux/settings';
import styles from './snackbar-error.styles';
import { IRootStore } from '../../typings/interfaces';

const useStyles = makeStyles(styles);

const SnackbarError: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const settings = useSelector((state: IRootStore) => state.settings);
  const { snackbar } = settings;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackbar.error.show}
      onClose={() => dispatch(settingsActions.hideSnackbarError())}
      autoHideDuration={3000}
    >
      <SnackbarContent
        className={classes.error}
        aria-describedby="error-id"
        message={
          <span id="error-id" className={classes.message}>
            <ErrorIcon />
            {snackbar.error.message && <FormattedMessage id={snackbar.error.message} />}
          </span>
        }
      />
    </Snackbar>
  );
};

export default SnackbarError;
