import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import React from 'react';
import { history } from '../../redux/store';
import ForwardButton from '../forward-button';
import BackwardButton from '../backward-button';
import styles from './footer.styles';
import routes from '../global/routes';

const useStyles = makeStyles(styles);

const Footer: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleBackwardClick = () => {
    let currentRouteObject;
    let counter = 0;
    Object.keys(routes).map(key => {
      // @ts-ignore
      if (routes[key].path === history.location.pathname) {
        counter += 1;
        // @ts-ignore
        currentRouteObject = routes[key];
        if (counter === 1) {
          // @ts-ignore
          dispatch(push(routes[currentRouteObject.previous].path));
        }
      }
      return true;
    });
  };

  return (
    <div className={classes.Footer}>
      <Grid container alignContent="center" justify="center" style={{ height: '100%' }}>
        <Grid item md={1} xs={4} className={classes.footerItem}>
          <BackwardButton onClick={handleBackwardClick} />
        </Grid>
        <Grid item md={10} xs={4} className={classes.footerItem} />
        <Grid item md={1} xs={4} className={classes.footerItem}>
          <ForwardButton />
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
