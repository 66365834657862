export default {
  REQUEST_CREATE_DEAL: 'DEAL_REQUEST_CREATE_DEAL',
  SUCCESS_CREATE_DEAL: 'DEAL_SUCCESS_CREATE_DEAL',
  ERROR_CREATE_DEAL: 'DEAL_ERROR_CREATE_DEAL',
  REQUEST_CREATE_CONTRACT: 'DEAL_REQUEST_CREATE_CONTRACT',
  SUCCESS_CREATE_CONTRACT: 'DEAL_SUCCESS_CREATE_CONTRACT',
  ERROR_CREATE_CONTRACT: 'DEAL_ERROR_CREATE_CONTRACT',
  REQUEST_GET_DEAL: 'DEAL_REQUEST_GET_DEAL',
  SUCCESS_GET_DEAL: 'DEAL_SUCCESS_GET_DEAL',
  ERROR_GET_DEAL: 'DEAL_ERROR_GET_DEAL',
  GATEWAY_CHECKOUT: 'DEAL_GATEWAY_CHECKOUT',
  REQUEST_CHECKOUT_PERSONALDATA: 'DEAL_REQUEST_CHECKOUT_PERSONALDATA',
  SUCCESS_CHECKOUT_PERSONALDATA: 'DEAL_SUCCESS_CHECKOUT_PERSONALDATA',
  ERROR_CHECKOUT_PERSONALDATA: 'DEAL_ERROR_CHECKOUT_PERSONALDATA',
  UPDATE_PROPERTY: 'DEAL_UPDATE_PROPERTY',
  CHECK_CONTRACT: 'DEAL_CHECK_CONTRACT'
};
