import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import styles from './forward-button.styles';

const useStyles = makeStyles(styles);

const ForwardButton = () => {
  const classes = useStyles();
  return (
    <Fab
      variant="extended"
      aria-label="Add"
      id="forwardButton"
      name="forwardButton"
      type="submit"
      form="mainForm"
      className={classes.forwardButton}
    >
      <KeyboardArrowRight className={classes.forwardButtonIcon} />
    </Fab>
  );
};

export default ForwardButton;
