import React from 'react';
import { makeStyles, useTheme, MobileStepper, Link, IconButton, Checkbox, Fab, Grid } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { settingsActions } from '../../../redux/settings';
import { dealActions } from '../../../redux/deal';
import Intro01 from '../../../static/images/intro_01.png';
import Intro05 from '../../../static/images/intro_05.png';
import styles from './welcome-modal-steps.styles';
import { ICustomTheme, ISettingsStore, ITenantStore } from '../../../typings/interfaces';

const useStyles = makeStyles(styles);

interface IWelcomeModalSteps {
  settings: ISettingsStore;
  tenant: ITenantStore;
  openLicenseModal: (type: string) => void;
}
const WelcomeModalSteps: React.FC<IWelcomeModalSteps> = props => {
  const { settings, tenant, openLicenseModal } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [processDataFieldActive, setProcessDataFieldActive] = React.useState(false);

  const theme = useTheme() as ICustomTheme;
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    creditModel: { generateContractDocuments }
  } = tenant;

  const tutorialSteps = [
    {
      label: <FormattedMessage id="app.intro.headline.1" />,
      imgPath: Intro01,
      body:
        generateContractDocuments === 'CONTRACT_DOCUMENTS' ? (
          <FormattedHTMLMessage id="app.intro.body.1.binding" />
        ) : (
          <FormattedHTMLMessage id="app.intro.body.1" />
        )
    },
    {
      label: <FormattedMessage id="app.intro.headline.4" />,
      imgPath: Intro05,
      body: <></>
    },
    {
      label: <FormattedMessage id="app.intro.headline.5" />,
      imgPath: Intro05,
      body: <></>
    }
  ];

  const maxSteps = tutorialSteps.length;

  let acceptedAll = false;
  if (
    settings.welcomeModal.acceptedPrivacy &&
    settings.welcomeModal.acceptedTerms &&
    settings.welcomeModal.processData
  ) {
    acceptedAll = true;
  }

  let acceptedPrivacy = false;
  if (settings.welcomeModal.acceptedPrivacy && settings.welcomeModal.processData) {
    acceptedPrivacy = true;
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleAcceptTerms = () => {
    dispatch(settingsActions.acceptTerms());
  };

  const handleAcceptPrivacy = () => {
    dispatch(settingsActions.acceptPrivacy());
  };

  const handleProcessData = () => {
    dispatch(settingsActions.processData());
  };

  const handleCloseWelcomeModal = () => {
    dispatch(dealActions.createDeal());
  };

  const submitForm = (event: any) => {
    event.preventDefault();
    handleCloseWelcomeModal();
  };

  const textareaRef = React.useRef();

  const handleTextareaScroll = () => {
    if (textareaRef.current) {
      // @ts-ignore
      const { scrollTop, scrollHeight, clientHeight } = textareaRef.current;
      if (scrollTop + clientHeight > scrollHeight - 60) {
        // TO SOMETHING HERE
        setProcessDataFieldActive(true);
      }
    }
  };

  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.leftBox}>
        <img src={tutorialSteps[activeStep].imgPath} alt="intro_image" className={classes.introImage} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.rightBox}>
        <span onClick={() => openLicenseModal('imprint')} className={classes.imprint}>
          <FormattedMessage id="app.imprint.title" />
        </span>
        <SwipeableViews axis="x" index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents disabled>
          {tutorialSteps.map((step, index) => (
            <React.Fragment key={`step${index}`}>
              <div className={classes.bodyInner} key={`step_${index}`}>
                <span className={classes.headline}>{step.label}</span>
                {step.body}
                {index === 1 && (
                  <div>
                    <div style={{ width: '100%', display: 'inline-flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '100%',
                          height: '120px',
                          marginTop: '20px',
                          marginBottom: '20px',
                          position: 'relative',
                          overflowY: 'scroll',
                          border: '1px solid black'
                        }}
                        onScroll={() => handleTextareaScroll()}
                        // @ts-ignore
                        ref={textareaRef}
                      >
                        {tenant.baseData.consent && <Markdown>{tenant.baseData.consent}</Markdown>}
                      </div>
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Checkbox
                        value="processData"
                        checked={settings.welcomeModal.processData}
                        onClick={handleProcessData}
                      />
                      <FormattedMessage
                        id="app.processData.description"
                        values={{
                          privacy: (
                            <Link className={classes.link} onClick={() => openLicenseModal('dataPrivacyDocument')}>
                              <FormattedMessage id="app.privacy.title" />
                            </Link>
                          )
                        }}
                      />
                    </div>
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Checkbox
                        value="acceptedPrivacy"
                        checked={settings.welcomeModal.acceptedPrivacy}
                        onClick={handleAcceptPrivacy}
                      />
                      <FormattedMessage
                        id="app.privacy.description"
                        values={{
                          privacy: (
                            <Link className={classes.link} onClick={() => openLicenseModal('dataPrivacyDocument')}>
                              <FormattedMessage id="app.privacy.title" />
                            </Link>
                          )
                        }}
                      >
                        {(...txt) => <span className={classes.linkText}>{txt}</span>}
                      </FormattedMessage>
                    </div>
                  </div>
                )}
                {index === 2 && (
                  <form onSubmit={submitForm} method="POST">
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Checkbox
                        value="acceptedTerms"
                        checked={settings.welcomeModal.acceptedTerms}
                        onClick={handleAcceptTerms}
                      />
                      <FormattedMessage
                        id="app.termsOfUseUrl.description"
                        values={{
                          termsOfUse: (
                            <>
                              <Link className={classes.link} onClick={() => openLicenseModal('termsOfUse')}>
                                <FormattedMessage id="app.termsOfUseUrl.title" />
                              </Link>
                            </>
                          )
                        }}
                      >
                        {(...txt) => <span className={classes.linkText}>{txt}</span>}
                      </FormattedMessage>
                    </div>
                    <hr />
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <FormattedMessage
                        id="app.preContractualInformation.description"
                        values={{
                          preContractualInformation: (
                            <Link
                              className={classes.link}
                              onClick={() => openLicenseModal('preContractualInformation')}
                            >
                              <FormattedMessage id="app.preContractualInformation.title" />
                            </Link>
                          )
                        }}
                      >
                        {(...txt) => <span className={classes.linkText}>{txt}</span>}
                      </FormattedMessage>
                    </div>
                    {acceptedAll && (
                      <div style={{ width: '100%', display: 'inline-flex', alignItems: 'center' }}>
                        <Fab
                          variant="extended"
                          id="startNowButton"
                          name="startNowButton"
                          type="submit"
                          size="small"
                          disabled={settings.forwardButtonDisabled}
                          style={{
                            background: theme.color.secondary,
                            color: theme.color.font.light,
                            marginTop: '20px',
                            width: '200px',
                            marginLeft: '-100px',
                            left: '50%',
                            paddingLeft: '20px',
                            borderRadius: '36px',
                            height: '50px',
                            paddingRight: '20px'
                          }}
                        >
                          Jetzt starten
                        </Fab>
                      </div>
                    )}
                  </form>
                )}
              </div>
            </React.Fragment>
          ))}
        </SwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          classes={{
            dots: classes.dots,
            dotActive: classes.dotActive
          }}
          activeStep={activeStep}
          className={classes.welcomeSteps}
          nextButton={
            <IconButton
              size="small"
              onClick={handleNext}
              className={classes.forwardButton}
              disabled={activeStep === maxSteps - 1 || (activeStep === 1 && !acceptedPrivacy)}
            >
              <KeyboardArrowRight />
            </IconButton>
          }
          backButton={
            <IconButton
              size="small"
              onClick={handleBack}
              className={classes.backwardButton}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>
          }
        />
      </Grid>
    </Grid>
  );
};

export default WelcomeModalSteps;
