import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './action-button.styles';

const useStyles = makeStyles(styles);

interface IActionButton {
  title: string;
  inversed?: boolean;
  action: any;
  sm?: boolean;
  fullWidth?: boolean;
  translate?: boolean;
  classExtra?: string;
  style?: any;
}
const ActionButton: React.FC<IActionButton> = props => {
  const classes = useStyles();
  const { title, action, classExtra, sm, inversed, style, fullWidth, translate } = props;
  let classNames = classnames(classes.actionButton, classExtra);
  if (sm) {
    classNames = classnames(classes.sm, classNames);
  }
  if (inversed) {
    classNames = classnames(classNames, classes.inversed);
  }
  if (fullWidth) {
    classNames = classnames(classNames, classes.fullWidth);
  }
  return (
    <Fab variant="extended" aria-label="Add" type="button" className={classNames} onClick={action} style={style}>
      {translate ? <FormattedMessage id={title} /> : title}
    </Fab>
  );
};

ActionButton.defaultProps = {
  classExtra: '',
  sm: false,
  inversed: false,
  style: {},
  translate: true,
  fullWidth: false
};

export default ActionButton;
