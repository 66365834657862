import { createStyles } from '@material-ui/core';
import { ICustomTheme } from '../../../typings/interfaces';

const styles = (theme: ICustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: 'center',
      display: 'grid'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.responseSpacing.unit * 4,
      backgroundColor: theme.palette.background.default
    },
    img: {
      top: '0px',
      background: theme.color.background.light,
      position: 'absolute',
      padding: '10px',
      paddingRight: '50px',
      left: '0px',
      width: '240px',
      height: '240px',
      zIndex: 9998
    },
    forwardButton: {
      position: 'relative',
      marginRight: '40px',
      marginBottom: '20px',
      color: theme.color.background.light,
      background: theme.color.secondary,
      width: '50px',
      height: '50px',
      '&:hover': {
        color: theme.color.background.light,
        background: theme.color.main
      }
    },
    backwardButton: {
      position: 'relative',
      left: '0px',
      marginLeft: '40px',
      marginBottom: '20px',
      color: theme.color.background.light,
      background: theme.color.secondary,
      width: '50px',
      height: '50px',
      '&:hover': {
        color: theme.color.background.light,
        background: theme.color.main
      }
    },
    body: {
      background: theme.color.main,
      color: theme.color.font.light,
      zIndex: 9999,
      width: '420px'
    },
    bodyInner: {
      padding: '40px',
      marginTop: '10px',
      fontSize: theme.fontSize.stepper.text.lg,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.fontSize.stepper.text.xs,
        marginTop: '0px',
        padding: '20px'
      },
      textAlign: 'left'
    },
    welcomeSteps: {
      background: 'transparent'
    },
    headline: {
      display: 'block',
      textAlign: 'left',
      fontSize: theme.fontSize.stepper.headline.lg,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.fontSize.stepper.headline.xs
      },
      fontWeight: 'bold',
      marginBottom: '32px'
    },
    dots: {
      marginBottom: '20px'
    },
    dotActive: {
      background: theme.color.main
    },
    link: {
      color: `${theme.color.main} !important`,
      textDecoration: 'underline',
      fontSize: '18px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px'
      }
    },
    linkText: {
      [theme.breakpoints.down('md')]: {
        fontSize: '14px'
      }
    },
    leftBox: {
      background: theme.color.main,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    rightBox: {
      background: theme.color.background.light,
      color: theme.color.main
    },
    introImage: {
      maxWidth: '440px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '200px'
      }
    },
    imprint: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      marginRight: '30px',
      marginTop: '25px',
      fontSize: '12px',
      zIndex: 9999,
      '&:hover': {
        cursor: 'pointer'
      }
    }
  });

export default styles;
