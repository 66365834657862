export default {
  REQUEST_SEARCH_BANK: 'BANK_REQUEST_SEARCH_BANK',
  SUCCESS_SEARCH_BANK: 'BANK_SUCCESS_SEARCH_BANK',
  ERROR_SEARCH_BANK: 'BANK_ERROR_SEARCH_BANK',
  SET_SELECTED_BANK: 'BANK_SET_SELECTED_BANK',
  RESET_BANK: 'BANK_RESET_BANK',
  SELECT_BANK: 'BANK_SELECT_BANK',
  SET_IBANS: 'BANK_SET_IBANS',
  SET_CREDENTIALS: 'BANK_SET_CREDENTIALS',
  REQUEST_CREATE_ACCESS: 'BANK_REQUEST_CREATE_ACCESS',
  SUCCESS_CREATE_ACCESS: 'BANK_SUCCESS_CREATE_ACCESS',
  ERROR_CREATE_ACCESS: 'BANK_ERROR_CREATE_ACCESS',
  REQUEST_GET_ACCESS_SYNC_STATUS: 'BANK_REQUEST_GET_ACCESS_SYNC_STATUS',
  SUCCESS_GET_ACCESS_SYNC_STATUS: 'BANK_SUCCESS_GET_ACCESS_SYNC_STATUS',
  ERROR_GET_ACCESS_SYNC_STATUS: 'BANK_ERROR_GET_ACCESS_SYNC_STATUS',
  REQUEST_SELECT_AUTH_METHOD: 'BANK_REQUEST_SELECT_AUTH_METHOD',
  SUCCESS_SELECT_AUTH_METHOD: 'BANK_SUCCESS_SELECT_AUTH_METHOD',
  ERROR_SELECT_AUTH_METHOD: 'BANK_ERROR_SELECT_AUTH_METHOD',
  REQUEST_POST_ACCESS_LOGIN_SYNC: 'BANK_REQUEST_POST_ACCESS_LOGIN_SYNC',
  SUCCESS_POST_ACCESS_LOGIN_SYNC: 'BANK_SUCCESS_POST_ACCESS_LOGIN_SYNC',
  ERROR_POST_ACCESS_LOGIN_SYNC: 'ERROR_POST_ACCESS_LOGIN_SYNC',
  START_ACCESS: 'BANK_START_ACCESS',
  START_SCENARIO: 'BANK_START_SCENARIO',
  REQUEST_GET_SCENARIOS: 'BANK_REQUEST_GET_SCENARIOS',
  SUCCESS_GET_SCENARIOS: 'BANK_SUCCESS_GET_SCENARIOS',
  ERROR_GET_SCENARIOS: 'BANK_ERROR_GET_SCENARIOS',
  REQUEST_GET_TOKEN: 'BANK_REQUEST_GET_TOKEN',
  SUCCESS_GET_TOKEN: 'BANK_SUCCESS_GET_TOKEN',
  ERROR_GET_TOKEN: 'BANK_ERROR_GET_TOKEN'
};
