import React from 'react';
import { Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Markdown from 'markdown-to-jsx';
import WelcomeModalSteps from './welcome-modal-steps';
import LicenseModal from './modal';
import { ISettingsStore, ITenantStore } from '../../typings/interfaces';

interface WindowVersion extends Window {
  version: string;
}

const windowUnknown = window as unknown;
const windowVersion = windowUnknown as WindowVersion;
interface IWelcomeModal {
  settings: ISettingsStore;
  tenant: ITenantStore;
}
const WelcomeModal: React.FC<IWelcomeModal> = props => {
  const [navbarModalOpen, setOpen] = React.useState(false);
  const [navbarModalContent, setContent] = React.useState(<></>);
  const [navbarModalHeadline, setHeadline] = React.useState(<></>);
  const { settings, tenant } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const versionString = `Version ${windowVersion.version}`;

  const handleModalOpen = (type: string) => {
    let headline = <></>;
    let content = <></>;
    const { baseData } = tenant;

    switch (type) {
      case 'termsOfUse':
        headline = <FormattedMessage id="app.termsOfUseUrl.title" />;
        content = <Markdown>{baseData.termsOfUse}</Markdown>;
        break;

      case 'dataPrivacyDocument':
        headline = <FormattedMessage id="app.privacy.title" />;
        content = <Markdown>{baseData.dataPrivacyDocument}</Markdown>;
        break;

      case 'preContractualInformation':
        headline = <FormattedMessage id="app.preContractualInformation.title" />;
        content = <Markdown>{baseData.preContractualInformation}</Markdown>;
        break;

      case 'imprint':
        headline = <FormattedMessage id="app.imprint.title" />;
        content = (
          <>
            <p>
              <strong>{versionString}</strong>
            </p>
            <a href={baseData.imprint} target="_blank" rel="noopener noreferrer">
              {baseData.imprint}
            </a>
          </>
        );

        break;

      default:
        break;
    }

    setContent(content);
    setHeadline(headline);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={settings.welcomeModal.show}
      fullScreen={fullScreen}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: '100%',
          height: '100%'
        }
      }}
    >
      <DialogContent>
        <WelcomeModalSteps settings={settings} tenant={tenant} openLicenseModal={handleModalOpen} />
        <LicenseModal
          open={navbarModalOpen}
          content={navbarModalContent}
          fullScreen={fullScreen}
          onClose={handleModalClose}
          headline={navbarModalHeadline}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeModal;
